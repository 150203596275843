import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getResponse } from "../../../utils/axios_api_utils"; // Replace with your API utility
import CustomModal from "./CustomModal";

const FollowersDialog = ({ open, handleClose, userId }) => {
  const [followers, setFollowers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch followers
  const fetchFollowers = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await getResponse( `user/followers/${userId}`);
      setFollowers(response.data.Items || []); // Assumes followers are in `Items`
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch followers.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchFollowers();
    }
  }, [open]);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Followers"
      searchPlaceholder="Search followers..."
      emptyMessage="No followers to display."
      data={followers}
      isLoading={isLoading}
      errorMessage={error}
    />
  );
};

FollowersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default FollowersDialog;
