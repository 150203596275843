export const reportReasonColumns = [
    {
      accessorKey: "reason",
      header: "Reason",
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <span className={`status ${cell.getValue() === 1 ? "active" : "inactive"}`}>
          {cell.getValue() === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];
  