import React, { useState } from 'react';
import {
  Box,
  Button,
  Card as MuiCard,
  CssBaseline,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
  Alert,
  Grid,
  styled,
  useTheme,
} from '@mui/material';
import { LightMode as LightModeIcon, DarkMode as DarkModeIcon, Visibility,VisibilityOff, Email as EmailIcon,} from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from '../forget-password/ForgotPassword';
import { useAuth } from '../../../contexts/useAuth';
import useSignInForm from './hooks/useSignInForm';
import useRememberMe from './hooks/useRememberMe';
import useThemeToggle from './hooks/useThemeToggle';
import logo from '../../../assets/images/logo.png';
import { Typography } from '@mui/material';

// Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: { maxWidth: '400px' },
  [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
  boxShadow:
  theme.palette.mode === 'dark' ? '0px 5px 15px rgba(0,0,0,0.5)' : '0px 5px 15px rgba(0,0,0,0.1)',
}));

const SignInContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background:
    theme.palette.mode === 'dark'
     ? 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))': 'radial-gradient(circle, #e0f7fa, #ffffff)',
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  // position: 'relative',
  // marginBottom: theme.spacing(2),
  // textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

// SignIn Component
const SignIn = () => {
  const theme = useTheme();
  const { toggleTheme } = useThemeToggle();
  const { login } = useAuth();

  // Local States
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  // Custom Hooks
  const { loginData, errors, loading, apiError, handleInputChange, handleSubmit } =
    useSignInForm({
      onSuccess: (token) => {
        login(token);
      },
    });

  const { toggleRememberMe } = useRememberMe((data) => {
    handleInputChange({ target: { name: 'email', value: data.email } });
    handleInputChange({ target: { name: 'password', value: data.password } });
  });

  const handleRememberChange = () => {
    toggleRememberMe(!rememberMe, loginData.email, loginData.password);
    setRememberMe(!rememberMe);
  };

  return (
    <>
      {/* Toast Notifications */}
      <ToastContainer autoClose={1000} />
      <CssBaseline enableColorScheme />
      <SignInContainer>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={6}>
            {/* Card for Sign-In Form */}
            <Card variant="outlined">
              <LogoContainer>
                {/* Theme Toggle Button */}
                <IconButton
                  onClick={toggleTheme}
                  sx={{ position: 'absolute', top: 16, right: 16 }}>
                  {theme.palette.mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
                </IconButton>
                {/* App Logo */}

                <img src={logo} alt="Logo" style={{ width: '120px' }} />
                <Typography variant="h6" fontWeight="bold">
                  Sign In
                </Typography>
              </LogoContainer>
              {/* API Error Display */}
              {apiError && <Alert severity="error">{apiError}</Alert>}
              {/* Sign-In Form */}
              <form onSubmit={handleSubmit} noValidate>
                {/* Email Field */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel>Email</FormLabel>
                  <TextField
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={loginData.email}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    required
                    inputProps={{ autoComplete: 'email'}}
                    InputProps={{
                      startAdornment: (
                        <IconButton edge="start">
                          <EmailIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>

                {/* Password Field */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel>Password</FormLabel>
                  <TextField
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={loginData.password}
                    onChange={handleInputChange}
                    placeholder='Must have at least 8 characters'
                    variant="outlined"
                    size="small"
                    required
                    inputProps={{  autoComplete: 'current-password' }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>
                {/* Remember Me Checkbox */}
                <FormControlLabel
                  control={<Checkbox checked={rememberMe} onChange={handleRememberChange} />}
                  label="Remember me"
                />
                {/* Submit Button */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Sign in'}
                </Button>
                {/* Forgot Password Link */}
                <Link
                  onClick={() => setForgotPasswordOpen(true)}
                  sx={{ mt: 2,textAlign:'center', display: 'block', cursor: 'pointer'}}>
                  Forgot your password?
                </Link>
              </form>
            </Card>
          </Grid>
        </Grid>
      </SignInContainer>

      {/* Forgot Password Dialog */}
      <ForgotPassword open={forgotPasswordOpen} handleClose={() => setForgotPasswordOpen(false)} />
    </>
  );
};

export default SignIn;


