import React from 'react';
import { Box, Typography } from '@mui/material';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

// Reusable MessageCard Component
const MessageCard = ({ icon: Icon, title, message, iconColor = 'rgba(0, 0, 0, 0.4)' }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 4,
      padding: 2,
      borderRadius: 2,
    }}>
    <Icon sx={{ fontSize: 64, color: iconColor, marginBottom: 2,}} />
    <Typography
      variant="h6"
      color="textSecondary"
      sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 1 }} >
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
      {message}
    </Typography>
  </Box>
);

// VideoMessage Component
const VideoMessage = ({ title , message }) => (
  <MessageCard
    icon={VideocamOffIcon}
    title={`No ${title} Videos Available`}
    message="We couldn't find any videos at the moment. Please check back later or refresh the page."
  />
);
// UserMessage Component
const UserMessage = () => (
  <MessageCard
    icon={PersonOutlineIcon}
    title="No Users Found"
    message="It looks like there are no users available right now. Please check back later or refresh the page."
    iconColor="rgba(0, 0, 0, 0.3)"
  />
);

export { VideoMessage, UserMessage };
