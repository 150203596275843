import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/index.css";
import App from "./App";
import { ThemeToggleProvider } from "./contexts/ThemeContext";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./contexts/useAuth";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* Theme Context for Dark/Light Mode Toggle */}
    <ThemeToggleProvider>
      {/* MUI Baseline Styles */}
      <CssBaseline />
      {/* React Router for Navigation */}
      <BrowserRouter>
        {/* AuthProvider for Authentication State Management */}
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ThemeToggleProvider>
  </React.StrictMode>
);


