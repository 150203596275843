// src/App.js
import * as React from "react";
import AdminRoutes from "./components/routes/AdminRoutes";
import { Box } from "@mui/material";

function App() {
  return (
    <Box>
      <AdminRoutes />
    </Box>
  );
}
export default App;
