import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import Table from "../../components/common/table/Table";
import { usersColumns } from "../../data/users";
import useUsers from "./hook/useUsers"; // Import the custom hook

const Users = memo(() => {

  // Destructure values and functions from the custom hook
  const {
    users,
    currentPage,
    totalRecords,
    pageSize,
    loading,
    searchTerm,
    handlePageChange,
    handlePageSizeChange,
    handleGlobalFilterChange,
    
  } = useUsers(); // Use the custom hook

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px", fontWeight: "bold" }}>
      Users
      </Typography>
      <Table
        data={users}
        fields={usersColumns}
        currentPage={currentPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        onGlobalFilterChange={handleGlobalFilterChange}
        globalFilter={searchTerm}
        enableGlobalFilter
        isLoading={loading}
      />
    </Box>
  );
});

export default Users;
