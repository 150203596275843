import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import MostCommentedVideos from "../../pages/MostCommentedVideos";
import MostDownloadedVideos from "../../pages/MostDownloadedVideos";
import MostFollowedUsers from "../../pages/MostFollowedUsers";
import MostLikedVideos from "../../pages/MostLikedVideos";
import MostSharedVideos from "../../pages/MostSharedVideos";
import MostWatchedVideos from "../../pages/MostWatchedVideos";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-tabpanel-${index}`}
      aria-labelledby={`scrollable-tab-${index}`}
      {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => ({
  id: `scrollable-tab-${index}`,
  "aria-controls": `scrollable-tabpanel-${index}`,
});

const DashboardTab = () => {
  const [value, setValue] = useState(0);
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Array of tab labels and their corresponding components
  const tabData = [
    { label: "Most Commented Videos", component: <MostCommentedVideos /> },
    { label: "Most Downloaded Videos", component: <MostDownloadedVideos /> },
    { label: "Most Followed Users", component: <MostFollowedUsers /> },
    { label: "Most Liked Videos", component: <MostLikedVideos /> },
    { label: "Most Shared Videos", component: <MostSharedVideos /> },
    { label: "Most Watched Videos", component: <MostWatchedVideos /> },
  ];

  return (
    <Box sx={{ pt: "20px", pb: "20px" }}>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "10px",
        }}
      >
         <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="scrollable tabs example"
              variant="scrollable"
              scrollButtons="auto" // Show buttons automatically when needed
              allowScrollButtonsMobile // Enables scroll buttons on mobile
            >
              {tabData.map((tab, index) => (
                <Tab key={index} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>

          {tabData.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default DashboardTab;
