import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import Table from "../../components/common/table/Table";
// import { categoryColumns } from "../../data/categoryColumns";
// import useCategories from "./hooks/useCategories";

// Styled wrapper for consistent layout.
const ComponentWrapper = styled(Box)({
  marginTop: "10px",
  paddingBottom: "10px",
});

/**
 * Component to display categories table.
 */
const Categories = () => {
//   const { categories, loading } = useCategories();

  return (
    <Box sx={{ pt: "60px", pb: "20px" }}>
      <ComponentWrapper>
        <Typography variant="h6" sx={{ my: 3 }}>
          Categories
        </Typography>
        {/* <Table
          isLoading={loading}
          data={categories}
          fields={categoryColumns}
          numberOfRows={5}
          enableTopToolBar={false}
          enableBottomToolBar={false}
          enablePagination={true}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableEditing={false}
          enableColumnDragging={false}
        /> */}
      </ComponentWrapper>
    </Box>
  );
};

export default Categories;
