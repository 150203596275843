import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getResponse } from "../../../utils/axios_api_utils";

const useUsers = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query params from URL
  const queryParams = new URLSearchParams(location.search);
  const initialPageNumber = Number(queryParams.get("pageNumber")) || 1;
  const initialPageSize = Number(queryParams.get("pageSize")) || 10;

  // State variables
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPageNumber);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Default to an empty string
  const isFetched = useRef(false);

  /**
   * Fetch users from the API.
   */
  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const serviceType = "base";
      let endpoint = `users?pageNumber=${currentPage}&pageSize=${pageSize}`;
      if (searchTerm?.trim())  {endpoint += `&search=${encodeURIComponent(searchTerm)}`; }
      const response = await getResponse(serviceType, endpoint);
      if (response?.data) {
        setUsers(response.data || []);
        setTotalRecords(Number(response.headers["x-coreplatform-total-records"]) || 0);
      } else {
        console.error("Failed to fetch users: No response data.");
      }
    } catch (err) {
      console.error("Error fetching users:", err.message || err);
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize, searchTerm]);

  /**
   * Call `fetchUsers` when dependencies change or on initial render.
   */
  useEffect(() => {
    if (isFetched.current) {
      fetchUsers();
    } else {
      isFetched.current = true;
    }
  }, [fetchUsers]);

  /**
   * Sync query params in the URL with the state.
   */
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("pageNumber", String(currentPage));
    params.set("pageSize", String(pageSize));
    if (searchTerm) {
      params.set("search", searchTerm);
    } else {
      params.delete("search");
    }
    
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [currentPage, pageSize, searchTerm, location.pathname, navigate]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handle page size change
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page on page size change
  };

  // Handle global filter changes (debounced search functionality)
  const handleGlobalFilterChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to the first page on search
  };

  return {
    users,
    currentPage,
    totalRecords,
    pageSize,
    loading,
    searchTerm,
    handlePageChange,
    handlePageSizeChange,
    handleGlobalFilterChange,
  };
};

export default useUsers;
