// import styled from "@emotion/styled";
// import {
//   Autocomplete,
//   Box,
//   Button,
//   Chip,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useRef, useState } from "react";

// import { BiImageAdd } from "react-icons/bi";

// const UplaodVideo = () => {
//   const [category, setCategory] = useState("");
//   const imageInput = useRef(null);
//   const [image, setImage] = useState("");

//   const handleChange = (event) => {
//     setCategory(event.target.value);
//     console.log(category);
//   };

//   const UploadBox = styled(Box)({
//     marginTop: 30,
//     height: 200,
//     borderRadius: "10px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "column",
//     borderStyle: "dashed",
//     borderWidth: "2px",
//     borderColor: "divider",
//   });

//   return (
//     <Box sx={{ pt: "80px", pb: "20px" }}>
//       <Typography variant="h6" sx={{ marginBottom: "14px" }}>
//       uplaod video
//       </Typography>
//       <Paper
//         sx={{
//           boxShadow: "none !important",
//           borderRadius: "12px",
//           borderStyle: "solid",
//           borderWidth: "1px",
//           borderColor: "divider",
//           p: "20px",
//           // maxWidth: "1000px",
//           margin: "0 auto",
//           cursor: "pointer",
//           overflow: "hidden",
//         }}
//       >
      
       
      
//         <input
//           type="file"
//           hidden
//           ref={imageInput}
//           onChange={(e) => setImage(e.target.files[0])}
//         />
//         <UploadBox onClick={() => imageInput.current.click()}>
//           {image ? (
//             <img
//               src={image && URL.createObjectURL(image)}
//               alt=""
//               style={{ width: "100%", height: "100%", objectFit: "contain" }}
//             />
//           ) : (
//             <Box sx={{ textAlign: "center" }}>
//               <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
//               <Typography>
//                 Drop your video here or{" "}
//                 <span style={{ color: "#027edd", cursor: "pointer" }}>
//                   browse
//                 </span>
//               </Typography>
//               <Typography sx={{ fontSize: "12px" }}>
//                 video are allowed only
//               </Typography>
//             </Box>
//           )}
//         </UploadBox>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             mt: "30px",
//           }}
//         >
//           <Button variant="contained" sx={{ borderRadius: "20px" }}>
//             Submit
//           </Button>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default UplaodVideo;


// Import necessary dependencies

// Import necessary dependencies

// import styled from "@emotion/styled";
// import {
//   Box,
//   Button,
//   Paper,
//   Typography,
// } from "@mui/material";
// import React, { useRef, useState } from "react";
// import { BiImageAdd } from "react-icons/bi";
// import axios from "axios";

// const UPLOAD_API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/upload/video/unassigned";

// const UploadVideo = () => {
//   const imageInput = useRef(null);
//   const [videos, setVideos] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const token = localStorage.getItem("token");

//   const UploadBox = styled(Box)({
//     marginTop: 30,
//     borderRadius: "10px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "column",
//     borderStyle: "dashed",
//     borderWidth: "2px",
//     borderColor: "divider",
//   });

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       const files = Array.from(e.target.files);
//       if (files.length + videos.length > 10) {
//         alert("You can upload a maximum of 10 videos.");
//         return;
//       }
//       setVideos((prevVideos) => [...prevVideos, ...files]);
//     }
//   };

//   const handleUpload = async () => {
//     if (videos.length === 0) {
//       alert("Please select at least one video to upload.");
//       return;
//     }

//     setUploading(true);
//     try {
//       for (const video of videos) {
//         const formData = new FormData();
//         formData.append("file", video);

//         await axios.post(UPLOAD_API_URL, formData, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//       }

//       alert("Videos uploaded successfully!");
//       setVideos([]); // Clear videos after upload
//     } catch (error) {
//       console.error("Error uploading videos:", error);
//       alert("Failed to upload videos. Please try again.");
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <Box sx={{ pt: "80px", pb: "20px" }}>
//       <Typography variant="h6" sx={{ marginBottom: "14px" }}>
//         Upload Videos
//       </Typography>
//       <Paper
//         sx={{
//           boxShadow: "none !important",
//           borderRadius: "12px",
//           borderStyle: "solid",
//           borderWidth: "1px",
//           borderColor: "divider",
//           p: "20px",
//           margin: "0 auto",
//           overflow: "hidden",
//         }}
//       >
//         <input
//           type="file"
//           hidden
//           ref={imageInput}
//           multiple
//           accept="video/*"
//           onChange={handleFileChange}
//         />
//         <UploadBox onClick={() => imageInput.current.click()}>
//           {videos.length > 0 ? (
//             <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
//               {videos.map((video, index) => (
//                 <Box key={index} sx={{ width: "200px", position: "relative" }}>
//                   <video
//                     src={URL.createObjectURL(video)}
//                     controls
//                     style={{ width: "100%", height: "auto", objectFit: "contain" }}
//                   />
//                   <Typography sx={{ fontSize: "12px", mt: 1, textAlign: "center" }}>
//                     {video.name}
//                   </Typography>
//                 </Box>
//               ))}
//             </Box>
//           ) : (
//             <Box sx={{ textAlign: "center" }}>
//               <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
//               <Typography>
//                 Drop your videos here or {" "}
//                 <span style={{ color: "#027edd", cursor: "pointer" }}>browse</span>
//               </Typography>
//               <Typography sx={{ fontSize: "12px" }}>Videos are allowed only</Typography>
//             </Box>
//           )}
//         </UploadBox>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             mt: "30px",
//           }}
//         >
//           <Button
//             variant="contained"
//             sx={{ borderRadius: "20px" }}
//             onClick={handleUpload}
//             disabled={uploading}
//           >
//             {uploading ? "Uploading..." : "Submit"}
//           </Button>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default UploadVideo;




// Import necessary dependencies
// import styled from "@emotion/styled";
// import {
//   Box,
//   Button,
//   IconButton,
//   Paper,
//   Typography,
// } from "@mui/material";
// import React, { useRef, useState } from "react";
// import { BiImageAdd } from "react-icons/bi";
// import { MdClose } from "react-icons/md";
// import axios from "axios";

// const UPLOAD_API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/upload/video/unassigned";

// const UploadVideo = () => {
//   const imageInput = useRef(null);
//   const [videos, setVideos] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const token = localStorage.getItem("token");

//   const UploadBox = styled(Box)({
//     marginTop: 30,
//     borderRadius: "10px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "column",
//     borderStyle: "dashed",
//     borderWidth: "2px",
//     borderColor: "divider",
//   });

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       const files = Array.from(e.target.files);
//       if (files.length + videos.length > 10) {
//         alert("You can upload a maximum of 10 videos.");
//         return;
//       }
//       setVideos((prevVideos) => [...prevVideos, ...files]);
//     }
//   };

  // const handleRemoveVideo = (index) => {
  //   setVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  // };

//   const handleUpload = async () => {
//     if (videos.length === 0) {
//       alert("Please select at least one video to upload.");
//       return;
//     }

//     setUploading(true);
//     try {
//       for (const video of videos) {
//         const formData = new FormData();
//         formData.append("file", video);

//         await axios.post(UPLOAD_API_URL, formData, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//       }

//       alert("Videos uploaded successfully!");
//       setVideos([]); // Clear videos after upload
//     } catch (error) {
//       console.error("Error uploading videos:", error);
//       alert("Failed to upload videos. Please try again.");
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <Box sx={{ pt: "80px", pb: "20px" }}>
//       <Typography variant="h6" sx={{ marginBottom: "14px" }}>
//         Upload Videos
//       </Typography>
//       <Paper
//         sx={{
//           boxShadow: "none !important",
//           borderRadius: "12px",
//           borderStyle: "solid",
//           borderWidth: "1px",
//           borderColor: "divider",
//           p: "20px",
//           margin: "0 auto",
//           overflow: "hidden",
//         }}
//       >
//         <input
//           type="file"
//           hidden
//           ref={imageInput}
//           multiple
//           accept="video/*"
//           onChange={handleFileChange}
//         />
//         <UploadBox onClick={() => imageInput.current.click()}>
//           {videos.length > 0 ? (
//             <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
//               {videos.map((video, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     width: "200px",
//                     position: "relative",
//                     border: "1px solid #ccc",
//                     borderRadius: "8px",
//                     padding: "10px",
//                   }}
//                 >
//                   <IconButton
//                     onClick={() => handleRemoveVideo(index)}
//                     sx={{ position: "absolute", top: "10px", right: "10px"  ,color:"red"}}
//                   >
//                     <MdClose />
//                   </IconButton>
//                   <video
//                     src={URL.createObjectURL(video)}
//                     controls
//                     style={{ width: "100%", height: "auto", objectFit: "contain" }}
//                   />
//                   <Typography sx={{ fontSize: "12px", mt: 1, textAlign: "center" }}>
//                     {video.name}
//                   </Typography>
//                 </Box>
//               ))}
//             </Box>
//           ) : (
//             <Box sx={{ textAlign: "center" }}>
//               <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
//               <Typography>
//                 Drop your videos here or {" "}
//                 <span style={{ color: "#027edd", cursor: "pointer" }}>browse</span>
//               </Typography>
//               <Typography sx={{ fontSize: "12px" }}>Videos are allowed only</Typography>
//             </Box>
//           )}
//         </UploadBox>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             mt: "30px",
//           }}
//         >
//           <Button
//             variant="contained"
//             sx={{ borderRadius: "20px" }}
//             onClick={handleUpload}
//             disabled={uploading}
//           >
//             {uploading ? "Uploading..." : "Submit"}
//           </Button>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default UploadVideo;


import styled from "@emotion/styled";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import axios from "axios";

const UPLOAD_API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/upload/video/unassigned";

const UploadVideo = () => {
  const imageInput = useRef(null);
  const [videos, setVideos] = useState([]);
  const [uploading, setUploading] = useState(false);
  const token = localStorage.getItem("token");

  const UploadBox = styled(Box)({
    padding:"40px",
    marginTop: 30,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "divider",
  });


  const handleFileChange = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      if (files.length + videos.length > 10) {
        alert("You can upload a maximum of 10 videos.");
        return;
      }
      setVideos((prevVideos) => [...prevVideos, ...files]);
    }
  };

  const handleRemoveVideo = (index, event) => {
    event.stopPropagation(); // Prevent click propagation to parent
    const updatedVideos = videos.filter((_, i) => i !== index);
    setVideos(updatedVideos);
    if (updatedVideos.length === 0 && imageInput.current) {
      imageInput.current.value = ""; // Clear file input
    }
  };


  
  const handleUpload = async () => {
    if (videos.length === 0) {
      alert("Please select at least one video to upload.");
      return;
    }

    setUploading(true);
    try {
      for (const video of videos) {
        const formData = new FormData();
        formData.append("file", video);

        await axios.post(UPLOAD_API_URL, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      alert("Videos uploaded successfully!");
      setVideos([]); // Clear videos after upload
      if (imageInput.current) {
        imageInput.current.value = ""; // Reset file input
      }
    } catch (error) {
      console.error("Error uploading videos:", error);
      alert("Failed to upload videos. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Upload Videos
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <input
          type="file"
          hidden
          ref={imageInput}
          multiple
          accept="video/*"
          onChange={handleFileChange}
        />
        <UploadBox onClick={() => imageInput.current.click()}>
          {videos.length > 0 ? (
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {videos.map((video, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "200px",
                    position: "relative",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "5px",
                  }}
                >
                  <IconButton
                    onClick={(event) => handleRemoveVideo(index, event)}
                    sx={{ position: "absolute", top: "-20px", right: "-10px", color: "red" }}
                  >
                    <MdClose />
                  </IconButton>
                  <video
                    src={URL.createObjectURL(video)}
                    controls
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                
                </Box>
              ))}
            </Box>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
              <Typography>
                Drop your videos here or{" "}
                <span style={{ color: "#027edd", cursor: "pointer" }}>browse</span>
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>Videos are allowed only</Typography>
            </Box>
          )}
        </UploadBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            variant="contained"
            sx={{ borderRadius: "20px" }}
            onClick={handleUpload}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Submit"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default UploadVideo;
