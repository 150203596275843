import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  Box,
  Divider,
  TextField,
  InputAdornment,
  styled,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxHeight: "500px",
    width: "100%",
    maxWidth: "450px",
    borderRadius: "16px",
    overflowY: "auto",
  },
}));

const CustomModal = ({
  open,
  handleClose,
  title,
  searchPlaceholder,
  emptyMessage,
  isLoading,
  data,
  errorMessage,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter data based on the search term
  const filteredData = data?.filter((user) =>
    ["firstName", "lastName", "userName"]
      .map((key) => user[key]?.toLowerCase())
      .some((field) => field?.includes(searchTerm.toLowerCase()))
  );

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "divider",
          padding: "16px",
          height: '50px',
        }}
      >
        <Typography fontWeight="bold">{title}</Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={searchPlaceholder}
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearchTerm("")}
                  size="small"
                  aria-label="clear search"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: '#f0f0f0',
           borderRadius: '18px',
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                borderColor: "#ccc",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#1976d2",
              },
            },
          }}
        />
      </Box>

      <DialogContent dividers>
        {isLoading ? (
          <Box sx={{ p: 2 }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Skeleton variant="text" width="70%" />
                  <Skeleton variant="text" width="50%" />
                </Box>
              </Box>
            ))}
          </Box>
        ) : errorMessage ? (
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          </Box>
        ) : filteredData?.length > 0 ? (
          <List>
            {filteredData.map((user, index) => (
              <React.Fragment key={user.userName || index}>
                <ListItem>
                  <Avatar
                    src={user.profilePicture}
                    alt={`${user.firstName} ${user.lastName}`}
                    sx={{ mr: 2 }}
                  />
                  <ListItemText
                    primary={`${user.firstName || "Unknown"} ${user.lastName || ""}`}
                    secondary={`Username: ${user.userName || "N/A"}`}
                  />
                </ListItem>
                {index < filteredData.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              textAlign: "center",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {emptyMessage}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      userName: PropTypes.string,
      profilePicture: PropTypes.string,
    })
  ),
  errorMessage: PropTypes.string,
};

CustomModal.defaultProps = {
  searchPlaceholder: "Search...",
  emptyMessage: "No data available.",
  isLoading: false,
  data: [],
  errorMessage: "",
};

export default CustomModal;


// import React, { useState } from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   List,
//   ListItem,
//   ListItemText,
//   Avatar,
//   IconButton,
//   Typography,
//   Box,
//   Divider,
//   TextField,
//   InputAdornment,
//   styled,
//   Skeleton,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// // import BlockIcon from '@mui/icons-material/Block';
// import SearchIcon from '@mui/icons-material/Search';

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiPaper-root': {
//     maxHeight: '400px', // Constrain the height of the scrollable list
//     overflowY: 'auto', // Enable vertical scrolling
//     width: '100%',
//     maxWidth: '400px',
//     borderRadius: '16px',
//   },
// }));

// const CustomModal = ({ open, handleClose, title, searchPlaceholder, emptyMessage, isLoading, data }) => {
//   const [searchTerm, setSearchTerm] = useState('');

//   // Filter data based on the search term
//   const filteredData = data.filter(user =>
//     user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     user.userName.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
//       <DialogTitle
//         sx={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           borderBottom: 1,
//           height: '50px', // Set the desired height
//         //   backgroundColor: '#f5f5f5', // Set the desired background color
//           fontWeight:'bold',
//         }}
//       >
//         <Typography sx={{ fontWeight:'bold',}}>{title}</Typography>
//         <IconButton aria-label="close" onClick={handleClose}>
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>

//       <Box sx={{ p: 2 }}>
//         <TextField
//           fullWidth
//           variant="outlined"
//           placeholder={searchPlaceholder}
//           size="small"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <SearchIcon />
//               </InputAdornment>
//             ),
//             endAdornment: searchTerm && (
//               <InputAdornment position="end">
//                 <IconButton onClick={() => setSearchTerm('')} size="small">
//                   <CloseIcon fontSize="small" />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           sx={{
//             backgroundColor: '#f0f0f0',
//             borderRadius: '18px',
//             '& .MuiOutlinedInput-root': {
//               '& fieldset': {
//                 border: 'none',
//               },
//               '&:hover fieldset': {
//                 borderColor: '#888',
//               },
//               '&.Mui-focused fieldset': {
//                 borderColor: '#3f51b5',
//               },
//             },
//           }}
//         />
//       </Box>

//       <DialogContent dividers>
//       {isLoading ? (
//           <Box sx={{ padding: 2 }}>
//             {/* Skeleton Loader for each item */}
//             {Array.from(new Array(5)).map((_, index) => (
//               <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                 <Skeleton variant="circular" width={40} height={40} sx={{ marginRight: 2 }} />
//                 <Box sx={{ flexGrow: 1 }}>
//                   <Skeleton variant="text" width="80%" />
//                   <Skeleton variant="text" width="60%" />
//                 </Box>
//               </Box>
//             ))}
//           </Box>
//         ) : filteredData.length > 0 ? (
//           <List>
//             {filteredData.map((user, index) => (
//               <React.Fragment key={index}>
//                 <ListItem>
//                 <Avatar src={user.profilePicture} sx={{ mr: 2 }} />
//                   <ListItemText
//                     primary={`${user.firstName || 'Unknown'} ${user.lastName || ''}`.trim()}
//                     secondary={`Username: ${user.userName}`}
//                   />
//                   {/* <IconButton color="error">
//                     <BlockIcon />
//                   </IconButton> */}
//                 </ListItem>
//                 {index < filteredData.length - 1 && <Divider />}
//               </React.Fragment>
//             ))}
//           </List>
//         ) : (
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
//             <Typography variant="body2" align="center" color="text.secondary">
//               {emptyMessage}
//             </Typography>
//           </Box>
//         )}
//       </DialogContent>
//     </StyledDialog>
//   );
// };

// export default CustomModal;