import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

const useLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); 

  useEffect(() => {
       // Prevent multiple API calls
       if (isFetched.current) return; 
       isFetched.current = true;

      const fetchLanguages = async () => {
      setLoading(true);
      try {
        const response = await getResponse("general","getLanguages");
        if (response.status === 200) {
          const { data } = response.data;
          // Format data if necessary, otherwise directly set it
          setLanguages(data);
        } else {
          throw new Error(`Error: ${response.status}`); // Handle non-200 responses
        }
      } catch (err) {
        console.error("Failed to fetch languages:", err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLanguages();
  }, []);

  return { languages, loading };
};

export default useLanguages;
