import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import DashboardTab from "./component/home/DashboardTab";
import Stats from './component/stats/Stats';

const Dashboard = () => {
  const ComponentWrapper = styled(Box)({
    marginTop: "10px",
    paddingBottom: "10px",
  });
  
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px", fontWeight: "bold" }} >
        Dashboard
      </Typography>
      <ComponentWrapper>
        <Stats />
      </ComponentWrapper>
      <ComponentWrapper>
       <DashboardTab/>
      </ComponentWrapper>
    </Box>
  );
};

export default Dashboard;

