import React from "react";
import { IoArrowUndoSharp } from "react-icons/io5";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract path segments from the current location
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Determine if the back button should be shown
  const showBackButton = pathnames.length >= 2;

  // Media query for responsive behavior
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack direction={isMobile ? "column" : "row"}  spacing={isMobile ? 1 : 2}
     alignItems={isMobile ? "flex-start" : "center"}
      justifyContent="space-between"
      sx={{ mb: 3 }}
    >
      {/* Back Button */}
      {showBackButton && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<IoArrowUndoSharp />}
          onClick={() => navigate(-1)}
          sx={{
            fontWeight: "bold",
            borderRadius: "8px",
            textTransform: "none",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          }}
        >
          Back
        </Button>
      )}

      {/* Breadcrumbs */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="›"
        sx={{  fontSize: isMobile ? "0.85rem" : "1rem", }}>
        {/* Static "Dashboard" link */}
        <Typography
          component={RouterLink}
          to="/dashboard"
          variant="body1"
          color="primary"
          sx={{
            textDecoration: "none",
            fontWeight: "500",
            "&:hover": {
              color: theme.palette.primary.main,
              textDecoration: "underline",
            },
          }}>
          Dashboard
        </Typography>

        {/* Dynamic breadcrumb links */}
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <Typography
              key={to}
              variant="body1"
              color="text.secondary"
              sx={{
                fontWeight: "bold",
                fontSize: isMobile ? "0.85rem" : "1rem",
                textTransform: "capitalize",
              }}
            >
              {value.replace(/-/g, " ")}
            </Typography>
          ) : (
            <Typography
              key={to}
              component={RouterLink}
              to={to}
              variant="body1"
              color="primary"
              sx={{
                textDecoration: "none",
                fontSize: isMobile ? "0.85rem" : "1rem",
                textTransform: "capitalize",
                "&:hover": {
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
              }}
            >
              {value.replace(/-/g, " ")}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

export default Breadcrumb;




// ================== BREADCRM ONLY BACKBUTTON=========================
// import React from "react";
// import { IoArrowUndoSharp } from "react-icons/io5";
// import { useNavigate } from "react-router-dom";
// import { Box, Stack, Button, Typography } from "@mui/material";

// const Breadcrumb = ({ title = "Back", onBack }) => {
//   const navigate = useNavigate();

//   // Handle the back navigation
//   const handleBack = () => {
//     if (onBack) {
//       onBack(); // Call the custom back handler if provided
//     } else {
//       navigate(-1); // Default back navigation
//     }
//   };

//   return (
//     <Box
//       sx={{
//         mb: 4,
//         p: 2,
//         borderRadius: "12px",
//         backgroundColor: "background.paper",
//         boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
//         display: "flex",
//         flexDirection: { xs: "column", sm: "row" }, // Responsive layout
//         alignItems: { xs: "flex-start", sm: "center" }, // Adjust alignment for mobile
//         justifyContent: "space-between",
//       }}
//     >
//       {/* Back Button */}
//       <Button
//         startIcon={<IoArrowUndoSharp />}
//         onClick={handleBack}
//         variant="outlined"
//         size="small"
//         sx={{
//           textTransform: "none",
//           borderRadius: "8px",
//           mb: { xs: 2, sm: 0 }, // Add margin-bottom for mobile view
//         }}
//       >
//         {title}
//       </Button>

//       {/* Optional Content for Desktop View */}
//       {/* <Typography
//         variant="body2"
//         sx={{
//           display: { xs: "none", sm: "block" }, // Hide on mobile view
//           color: "text.secondary",
//         }}
//       >
//         Additional information or navigation can go here.
//       </Typography> */}
//     </Box>
//   );
// };

// export default Breadcrumb;



