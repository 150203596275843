import React from 'react';
import { Grid, Box,Typography } from '@mui/material';
import VideoCard from '../component/home/VideoCard';
import useVideos from '../hooks/useVideos';
import { GridLoading } from '../../../components/common/loader/Loader';
import { VideoMessage } from '../../../components/common/errorMessage/Message';

const MostDownloadedVideos = () => {
  const endpoint = 'dashboard/mostDownloadVideos';
  const { videos, loading, handlePlayPause, playingVideoId } = useVideos(endpoint);

  // Show loading spinner while fetching videos
  if (loading) {
    return <GridLoading/>;
  }

  if (!videos || videos.length === 0) {
    return ( <VideoMessage title="downloaded"/>);
   }
  // Render the list of videos
  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={3}>
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} key={video.id}>
            <VideoCard
              video={video}
              playingVideoId={playingVideoId}
              handlePlayPause={handlePlayPause}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MostDownloadedVideos;
