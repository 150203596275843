import React from "react";
import { singleUserDataColumns } from "../../../data/singleUserList";
import Table from "../../../components/common/table/Table";

const VideoTable = ({ videos,loading }) => {
  return (
    <Table
      isLoading={loading}
      data={videos}
      fields={singleUserDataColumns}
      numberOfRows={videos.length}
      enablePagination={false}
    />
  );
};
export default VideoTable;
