import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

/**
 * Custom Hook to fetch report reasons from the API.
 */
const useReportReasons = () => {
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // Prevent multiple API calls.

  useEffect(() => {
    if (isFetched.current) return; // Avoid duplicate fetch calls.
    isFetched.current = true;

    const fetchReportReasons = async () => {
      setLoading(true); // Start loading state.
      try {
        const response = await getResponse("general", "getReportReason");
        if (response.status === 200) {
          const { data } = response.data;
          setReasons(data); // Update the state with API data.
        } else {
          throw new Error(`API responded with status ${response.status}`);
        }
      } catch (error) {
        console.error("Failed to fetch report reasons:", error.message);
      } finally {
        setLoading(false); // End loading state.
      }
    };

    fetchReportReasons();
  }, []);

  return { reasons, loading };
};

export default useReportReasons;
