export const hashtagColumns = [
    {
      accessorKey: "name",
      header: "Hashtag Name",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      Cell: ({ cell }) =>
        new Date(cell.getValue()).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <span
          className={`status ${cell.getValue() === 1 ? "active" : "inactive"}`}
        >
          {cell.getValue() === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];
  