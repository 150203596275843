import React from "react";
import { Avatar, Box, Paper, Typography } from "@mui/material";

const UserCard = ({ user }) => {
  return (
    <Paper
      sx={{
        borderRadius: "12px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgba(0, 0, 0, 0.12)", // Light border for differentiation
        p: "20px",
        bgcolor: "#f9f9f9", // Slightly off-white background
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-10px)", // Slide up
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Avatar
          src={user.profilePicture || "/default-avatar.png"}
          alt={user.userName}
          sx={{
            width: { xs: "100px", sm: "120px" },
            height: { xs: "100px", sm: "120px" },
            mx: "auto",
            border: "3px solid rgba(0, 0, 0, 0.1)",
          }}
        />

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ my: 1, fontWeight: "bold" }}>
            {user.userName}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 1 }}>
            Name:{" "}
            <span style={{ opacity: 0.7 }}>
              {`${user.firstName || "N/A"} ${user.lastName || ""}`.trim()}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 1 }}>
            Email:{" "}
            <span style={{ opacity: 0.7 }}>{user.email || "N/A"}</span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 1 }}>
            DOB: <span style={{ opacity: 0.7 }}>{user.dob || "N/A"}</span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 1 }}>
            Status:{" "}
            <span
              style={{
                color: user.status === 1 ? "green" : "red", // Numeric status handling
                opacity: 0.7,
              }}
            >
              {user.status === 1 ? "Active" : "Inactive"}
            </span>
          </Typography>

          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 1 }}>
            Created At:{" "}
            <span style={{ opacity: 0.7 }}>
              {new Date(user.createdAt).toLocaleString() || "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 1 }}>
            Last Login:{" "}
            <span style={{ opacity: 0.7 }}>
              {new Date(user.lastLoginAt).toLocaleString() || "N/A"}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            mt: 3,
            textAlign: "center",
            bgcolor: "rgba(0, 0, 0, 0.05)",
            p: 2,
            borderRadius: "8px",
          }}
        >
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Posts
            </Typography>
            <Typography variant="body2">{user.totalVideos || 0}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Followers
            </Typography>
            <Typography variant="body2">{user.totalFollowers || 0}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Following
            </Typography>
            <Typography variant="body2">{user.totalFollowing || 0}</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default UserCard;
