import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

/**
 * Custom hook to fetch the most followed users from the API.
 * Manages loading state and prevents duplicate API calls.
 */
const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // Prevent multiple API calls.

  useEffect(() => {
    // Exit early if data is already fetched.
    if (isFetched.current) return;
    isFetched.current = true;

    const fetchUsers = async () => {
      setLoading(true);
      try {
        // Fetch data from the API
        const response = await getResponse("base","dashboard/mostFollowedUsers");
        if (response.status === 200) {
            setUsers(response?.data||[]);
        } else {
          console.error(`API Error: Received status code ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching most followed users:", error.message);
      } finally {
        setLoading(false); // Always reset the loading state.
      }
    };

    fetchUsers();
  }, []);

  return { users, loading };
};

export default useUsers;








// import { useState, useEffect, useRef } from "react";
// import { getResponse } from "../../../utils/axios_api_utils";

// const useUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const isFetched = useRef(false); // Track if the API call has already been made
//   useEffect(() => {
//     // Prevent multiple API calls
//     if (isFetched.current) return; 
//     isFetched.current = true;
//     const fetchUsers = async () => {
//         setLoading(true);
//       try {

//         const response = await getResponse("base","dashboard/mostFollowedUsers");
//         if (response.status === 200) {
//             setUsers(response?.data);
//         } else {
//           throw new Error(`Error: ${response.status}`); // Handle non-200 responses
//         }
//       } catch (err) {
//         console.error("Failed to fetch data:", err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsers();
//   }, []);

//   return { users, loading };
// };

// export default useUsers;
