export const singleUserDataColumns = [
  // {
  //   accessorKey: 'id',
  //   header: 'Video ID',
  // },
  {
    accessorKey: 'thumbUrl',
    header: 'Thumbnail',
    Cell: ({ cell }) => (
      <img
        src={cell.getValue()}
        alt="Thumbnail"
        style={{ width: '100px', height: 'auto', borderRadius: '8px' }}
      />
    ),
  },
  // {
  //   accessorKey: 'audioName',
  //   header: 'Audio Name',
  // },
  // {
  //   accessorKey: 'audioUrl',
  //   header: 'Audio URL',
  //   size: 300, // Width of the column
  //   Cell: ({ cell }) => (
  //     <span
  //       style={{
  //         whiteSpace: 'normal', // Allows text to wrap
  //         wordBreak: 'break-all', // Breaks long words/URLs onto the next line
  //       }}
  //     >
  //       {cell.getValue()}
  //     </span>
  //   ),
  // },
  
  {
    accessorKey: 'fullName', // If you want to merge first and last names
    header: 'Full Name',
    size: 100,
    Cell: ({ row }) => `${row.original.firstName || 'N/A'} ${row.original.lastName || ''}`.trim(), // Combine first and last name
  },
  {
    accessorKey: 'userName',
    header: 'User Name',
  },
  {
    accessorKey: 'createdAt',
    header: 'Created At',
    Cell: ({ cell }) => {
      const timestamp = cell.getValue();
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
  },
  {
    accessorKey: 'views',
    header: 'Views',
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: 'likes',
    header: 'Likes',
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: 'comments',
    header: 'Comments',
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: 'shares',
    header: 'Shares',
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => {
      const status = cell.getValue();
      const statusText = status === 1 ? 'Active' : 'Inactive';
      return <span className={`status ${statusText.toLowerCase()}`}>{statusText}</span>;
    },
  },
  // {
  //   accessorKey: 'actions',
  //   header: 'Actions',
  //   Cell: ({ row }) => (
  //     <div>
  //       <a href={row.original.videoUrl} target="_blank" rel="noopener noreferrer">
  //         Watch Video
  //       </a>
  //     </div>
  //   ),
  // },
];

