import React from 'react';
import ReactPlayer from 'react-player';
import { Card, CardContent, CardMedia, Typography, IconButton, Box, Stack, Button} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from '@mui/icons-material/Share';

// VideoCard Component
const VideoCard = ({ video, playingVideoId, handlePlayPause }) => {
  
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        overflow: 'hidden',
        border: 1,
        borderColor: 'rgba(255, 228, 186, 0.8)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
        },
      }}
    >
      <Box sx={{ position: 'relative', height: 200 }}>
        {/* Thumbnail Image */}
        <CardMedia
          component="img"
          image={video.thumbUrl}
          alt="Video Thumbnail"
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        {/* Video Player */}
        {playingVideoId === video.id && (
          <ReactPlayer
            url={video.iosUrl}
            playing={true}
            controls
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
          />
        )}
        {/* Play Button Overlay */}
        {playingVideoId !== video.id && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handlePlayPause(video.id)}
          >
            <PlayArrowIcon sx={{ fontSize: 50, color: '#fff' }} />
          </Box>
        )}
      </Box>

      <CardContent>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          {video.caption || 'No Caption Provided'}
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1}>
            <Button startIcon={<ThumbUpIcon />} size="small">
              {video.likes}
            </Button>
            <Button startIcon={<CommentIcon />} size="small">
              {video.comments}
            </Button>
            <Button startIcon={<ShareIcon />} size="small">
              {video.shares}
            </Button>
          </Stack>
          <IconButton
            onClick={() => handlePlayPause(video.id)}
            sx={{ backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '50%' }}
          >
            {playingVideoId === video.id ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default VideoCard;





// import React from 'react';
// import ReactPlayer from 'react-player';
// import {  Card,  CardContent, CardMedia, Typography, IconButton,  Box,  Stack,  Button} from '@mui/material';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
// import CommentIcon from '@mui/icons-material/Comment';
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import ShareIcon from '@mui/icons-material/Share';

// // VideoCard Component
// const VideoCard = ({ video, playingVideoId, handlePlayPause }) => {
//   return (
//     <Card
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         borderRadius: 2,
//         overflow: 'hidden',
//         border: 1,
//         borderColor: 'rgba(255, 228, 186, 0.8)',
//         transition: 'transform 0.3s, box-shadow 0.3s',
//         '&:hover': {
//           transform: 'scale(1.05)',
//           boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
//         },
//       }}
//     >
//       <Box
//         sx={{
//           position: 'relative',
//           // height: 200
//           height: { xs: 200, sm: 300, md: 250 }, // Responsive height
//         }}
//       >
//         {playingVideoId === video.id ? (
//           <ReactPlayer
//             url={video.iosUrl}
//             playing
//             controls
//             width="100%"
//             height="100%"
//             style={{ position: 'absolute', top: 0, left: 0 }}
//           />
//         ) : (
//           <>
//             <CardMedia
//               component="img"
//               image={video.thumbUrl}
//               alt="Video Thumbnail"
//               sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
//             />
//             <Box
//               sx={{
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 height: '100%',
//                 backgroundColor: 'rgba(0, 0, 0, 0.4)',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 cursor: 'pointer',
//               }}
//               onClick={() => handlePlayPause(video.id)}
//             >
//               <PlayArrowIcon sx={{ fontSize: 50, color: '#fff' }} />
//             </Box>
//           </>
//         )}
//       </Box>
//       <CardContent>
//         <Typography variant="subtitle2" sx={{ mb: 1 }}>
//           {video.caption || 'No Caption Provided'}
//         </Typography>
//         <Stack direction="row" justifyContent="space-between" alignItems="center">
//           <Stack direction="row" spacing={1}>
//             <Button startIcon={<ThumbUpIcon />} size="small">
//               {video.likes}
//             </Button>
//             <Button startIcon={<CommentIcon />} size="small">
//               {video.comments}
//             </Button>
//             <Button startIcon={<ShareIcon />} size="small">
//               {video.shares}
//             </Button>
//           </Stack>
//           <IconButton
//             onClick={() => handlePlayPause(video.id)}
//             sx={{ backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '50%' }}
//           >
//             {playingVideoId === video.id ? <PauseIcon /> : <PlayArrowIcon />}
//           </IconButton>
//         </Stack>
//       </CardContent>
//     </Card>
//   );
// };

// export default VideoCard;













