import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  OutlinedInput,
  FormControl,
  FormHelperText,
  CircularProgress,
  useTheme,
  styled,
} from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.mode === 'dark'   ? '0px 10px 30px rgba(0, 0, 0, 0.5)' : '0px 10px 30px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[400],
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
}));

function ForgotPassword({ open, handleClose }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  // Enhanced email validation using a regex for stricter format checking
  const validateEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(value);
    setEmailError(isValid ? '' : 'Please enter a valid email address');
    return isValid;
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    validateEmail(value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) return;
    setLoading(true);
    try {
      const response = await axios.post( `${process.env.REACT_APP_API_BASE_URL}/admin/forgetPassword`,
        { email },
        {
          headers: {'Content-Type':'application/json'},
        }
      );
      if (response.status === 201) {
        const token = response.headers['token'];
        toast.success('OTP sent successfully! Please check your email.');
        navigate('/verify-forgot-password-otp', { state: { token, email } });
      } else {
        toast.error('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      const errorMessage =
      error.response?.data?.message || 'An error occurred. Please try again later.';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };


  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ component: 'form', onSubmit: handleFormSubmit, }}>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.20rem', textAlign: 'center' }}>
        Reset Password
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText sx={{ color: theme.palette.text.secondary, fontSize: '0.95rem' }}>
          Enter your account's email address, and we'll send you a link to reset your password.
        </DialogContentText>
        <FormControl error={!!emailError} fullWidth>
          <StyledOutlinedInput
            autoFocus
            required
            size="small"
            id="email"
            name="email"
            placeholder="Email address"
            type="email"
            value={email}
            onChange={handleEmailChange}
            aria-describedby="email-helper-text"
            disabled={loading}/>
          {emailError && <FormHelperText id="email-helper-text">{emailError}</FormHelperText>}
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', gap: 2, pr: 3 }}>
      <Button
          variant="outlined"
          onClick={handleClose}
          disabled={loading}
          sx={{ borderRadius: 8, fontWeight: 'bold' }}
        >
          Cancel
        </Button>
        <Button
         sx={{ borderRadius: 8, fontWeight: 'bold' }}
         type="submit" variant="contained" disabled={!email || !!emailError || loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
