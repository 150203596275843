import { FiHome, FiUsers, FiGlobe, FiTag,FiVideo, FiFolder, FiAlertCircle } from "react-icons/fi";
import { FaVideo } from "react-icons/fa";
export const links = [
  {
    name: "Dashboard",
    icon: <FiHome />,
    url: "/dashboard",
  },
  {
    name: "Users",
    icon: <FiUsers />,
    url: "/users",
  },
  {
    name: "Languages",
    icon: <FiGlobe />,
    url: "/languages",
  },
  {
    name: "HashTags",
    icon: <FiTag />,
    url: "/hashTags",
  },
  {
    name: "Video Unassigned",
    icon: <FiVideo/>,
    url: "/video-unassigned",
  },
  // {
  //   name: "Category",
  //   icon: <FiFolder />,
  //   url: "/category",
  // },
  {
    name: "Report Reason",
    icon: <FiAlertCircle />,
    url: "/report-reason",
  },
];
