import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../../pages/auth/sign-in/SignIn";
import VerifyForgetPasswordOTP from "../../pages/auth/forget-password/verifyForgetPasswordOTP";
import ResetPassword from "../../pages/auth/forget-password/ResetPassword";
import ProtectedRoute from "./ProtectedRoute";
import LayoutWrapper from "../layout/LayoutWrapper";
// import PublicRoute from "./PublicRoute";
import NotFound from "../common/not-found/NotFound";
import {
  Dashboard,
  Users,
  SingleUser ,
  Languages,
  HashTags,
  ReportReasons,
  Categories
} from "../../pages";
import VideoUnassigned from "../../pages/video-unassigned/VideoUnassigned";
import UplaodVideo from "../../pages/video-unassigned/UplaodVideo";


 const AdminRoutes = () => {
  return (
    <Routes>
       {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/verify-forgot-password-otp" element={<VerifyForgetPasswordOTP />} />
      <Route path="/reset-password" element={<ResetPassword />} />
       {/* Protected Routes */}
    <Route element={<ProtectedRoute><LayoutWrapper /></ProtectedRoute>}>
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/users" element={<Users />} />
    <Route path="/users/:id" element={<SingleUser />} />
    <Route path="/languages" element={<Languages />} />
    <Route path="/hashTags" element={<HashTags />} />
    <Route path="/report-reason" element={<ReportReasons />} />
    <Route path="/category" element={<Categories />} />
    <Route path="/video-unassigned" element={<VideoUnassigned />} />
    <Route path="/video/upload" element={<UplaodVideo />} />
  </Route>
 {/* Catch-All Route */}
 <Route path="*" element={<NotFound/>} />
    </Routes>
  );
};

export default AdminRoutes;



