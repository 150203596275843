import React, { useState } from "react";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { mostFollowedUsersColumns } from "../../../data/followedUsersList"; 
import useUsers from "../hooks/useUsers";
import UserCard from "../component/home/UserCard";
import { UserCardLoader } from "../../../components/common/loader/Loader";
import Table from "../../../components/common/table/Table";
import { UserMessage } from "../../../components/common/errorMessage/Message";

const MostFollowedUsers = () => {
  const { users, loading } = useUsers();
  const [isTableView, setIsTableView] = useState(false);

  const handleToggleView = () => {
    setIsTableView((prev) => !prev);
  };

  // Show loader while fetching data
  if (loading) {
    return (
      <Box sx={{ mt: 4 }}>
        <UserCardLoader count={6} />
      </Box>
    );
  }

  // Show "No users" message only when data is fully loaded but empty
  if (!users || users.length === 0) {
    return <UserMessage />;
  }

  return (
    <Box>
      {/* Toggle Button */}
      <Box
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography>Card View</Typography>
        <Switch checked={isTableView} onChange={handleToggleView} color="primary" />
        <Typography>Table View</Typography>
      </Box>

      {isTableView ? (
        // Table View
        <Box sx={{ mt: 2 }}>
          <Table
            data={users} // Pass the users data
            fields={mostFollowedUsersColumns} // Use the provided column definitions
            numberOfRows={users.length}
            enableTopToolBar={false}
            enableBottomToolBar={false}
            enablePagination={false}
            enableRowSelection={false}
            enableColumnFilters={false}
            enableEditing={false}
            enableColumnDragging={false}
            isLoading={loading}
          />
        </Box>
      ) : (
        // Card View
        <Grid container spacing={3}>
          {users.map((user) => (
            <Grid item xs={12} md={6} lg={4} key={user.id}>
              <UserCard user={user} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default MostFollowedUsers;
