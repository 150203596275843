
import { useEffect, useState,useRef } from "react";
import { useParams } from "react-router-dom";
import { getResponse } from "../../../utils/axios_api_utils"; // Adjust the import path if needed

const useSingleUser = () => {
  const { id } = useParams();

  const [userData, setUserData] = useState(null);
  const [userVideos, setUserVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const isFetched = useRef(false); // Prevents duplicate API calls on mount
  const fetchData = async () => {
    setLoading(true);
    setError("");
  
    try {
      // Fetch user data
      const userResponse = await getResponse('base', `user/profile/${id}`);
      if (userResponse.status === 200) {
        setUserData(userResponse.data || null);
      } else {
        throw new Error(`Failed to fetch user data. Status: ${userResponse.status}`);
      }
      // Fetch user videos
      const videosResponse = await getResponse('base',`user/videos/${id}`);
      if (videosResponse.status === 200) {
        setUserVideos(videosResponse.data.Items || []);
      } else {
        throw new Error(`Failed to fetch user videos. Status: ${videosResponse.status}`);
      }
    } catch (err) {
      console.error("Error fetching data:", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!id) return; // If `id` is not defined, skip fetching.
    if (isFetched.current) {
      fetchData(); // Call fetchData if the component is updated with a new `id`.
    } else {
      isFetched.current = true; // Mark as fetched to prevent initial duplicate call.
    }
  }, [id]);
  


  const handleUploadSuccess = () => {
    fetchData(); // Refetch data after a successful upload
  };

  return {
    userData,
    userVideos,
    loading,
    error,
    handleUploadSuccess,
  };
};

export default useSingleUser;



