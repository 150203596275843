// import React, { useState, useEffect } from 'react';
// import {  Dialog,
//  DialogTitle,
//   DialogContent,
//   List,
//   ListItem,
//   ListItemText,
//   Avatar,
//   IconButton,
//   Typography,
//   CircularProgress,
//   Box,
//   Divider,
//   TextField,
//   InputAdornment,
//   styled,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import BlockIcon from '@mui/icons-material/Block';
// import axios from 'axios';
// import SearchIcon from '@mui/icons-material/Search';

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiPaper-root': {
//     maxHeight: '400px', // Constrain the height of the scrollable list
//     overflowY: 'auto', // Enable vertical scrolling
//     width: '100%',
//     maxWidth: '500px',
//     borderRadius: '16px',
//     width: '100%',
//     maxWidth: '500px',
//     borderRadius: '16px',
//   },
// }));



// const BlockUserDialog = ({ open, handleClose, userId }) => {
  // const [blockedUsers, setBlockedUsers] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState('');
//  const [searchTerm, setSearchTerm] = useState('');

//   // Filter followers based on the search term
//   // const filteredFollowers = followersData.filter(
//   //   (follower) =>
//   //     follower.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
//   //     follower.name.toLowerCase().includes(searchTerm.toLowerCase())
//   // );

  // useEffect(() => {
  //   if (open) {
  //     fetchBlockedUsers();
  //   }
  // }, [open]);

  // const fetchBlockedUsers = async () => {
  //   setIsLoading(true);
  //   setError('');
  //   try {
  //     const token =localStorage.getItem("token")
  //     const response = await axios.get(
  //       `https://m4h5sy01i9.execute-api.ap-south-1.amazonaws.com/dev/user/blocked/list/${userId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setBlockedUsers(response.data.Items || []); // Assume the blocked users list is in "Items"
  //   } catch (err) {
  //     setError(err.response?.data?.message || 'Failed to fetch blocked users.');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

//   const followersData = [
//     { username: 'suyash_bhawsar', name: 'Suyash Bhawsar' },
//     { username: 'vishal_bhati_17', name: 'भारत' },
//     { username: 'parantapdansana97', name: 'パパ' },
//     { username: 'o_brahman_putra', name: 'अयुष ~ पवित्र ~ 💙❤️' },
//     { username: 'mr_raja_ji_', name: 'Rãjã RãthØd Bãñjàrã' },
//     { username: 'user_1', name: 'User One' },
//     { username: 'user_2', name: 'User Two' },
//     { username: 'user_3', name: 'User Three' },
//     { username: 'user_4', name: 'User Four' },
//     { username: 'user_5', name: 'User Five' },
//     { username: 'o_brahman_putra', name: 'अयुष ~ पवित्र ~ 💙❤️' },
//     { username: 'mr_raja_ji_', name: 'Rãjã RãthØd Bãñjàrã' },
//     { username: 'user_1', name: 'User One' },
//     { username: 'user_2', name: 'User Two' },
//     { username: 'user_3', name: 'User Three' },
//     { username: 'user_4', name: 'User Four' },
//     { username: 'user_5', name: 'User Five' },
//   ];
  

//   return (
//     <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
//       <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',borderBottom:1 }}>
//         <Typography variant="h6">
//           Blocked Users
//         </Typography>
//         <IconButton aria-label="close" onClick={handleClose}>
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
     
      
//       <Box sx={{p:2}}>
//           <TextField
         
//             fullWidth
//             variant="outlined"
//             placeholder="Search followers..."
//             size="small"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <SearchIcon />
//                 </InputAdornment>
//               ),
//               endAdornment: searchTerm && (
//                 <InputAdornment position="end">
//                   <IconButton onClick={() => setSearchTerm('')} size="small">
//                     <CloseIcon fontSize="small" />
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//             sx={{
//               backgroundColor: '#f0f0f0', // Change background color
//               borderRadius: '18px', // Change border radius
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   border:"none !important"
//                   // borderColor: '#ccc', // Change border color
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#888', // Change border color on hover
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#3f51b5', // Change border color when focused
//                 },
//               },
//             }}
//           />
//         </Box>

//       <DialogContent dividers>

//         {isLoading ? (
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
//             <CircularProgress />
//           </Box>
//         ) : followersData.length > 0 ? (
//           <List>
//             {followersData.map((user, index) => (
//               <React.Fragment key={user.userId}>
//                 <ListItem>
//                   <Avatar sx={{ mr: 2 }}>{user.firstName?.charAt(0) || '?'}</Avatar>
//                   <ListItemText
//                     primary={`${user.firstName || 'Unknown'} ${user.lastName || ''}`.trim()}
//                     secondary={`Username: ${user.userName}`}
//                   />
//                   <BlockIcon color="error" />
//                 </ListItem>
//                 {index < followersData.length - 1 && <Divider />}
//               </React.Fragment>
//             ))}
//           </List>
//         ) : (
//           <Box
//           sx={{ display: 'flex',   justifyContent: 'center',  alignItems: 'center',  height: '200px',}}>
//           <Typography variant="body2" align="center" color="text.secondary">
//             No block user found.
//           </Typography>
//         </Box>
//         )}
//       </DialogContent>
//     </StyledDialog>
//   );
// };

// export default BlockUserDialog;












import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getResponse } from "../../../utils/axios_api_utils"; // Replace with your actual API utility
import CustomModal from "./CustomModal";

const BlockUserDialog = ({ open, handleClose, userId }) => {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch blocked users
  const fetchBlockedUsers = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await getResponse(`/user/blocked/list/${userId}`);
      setBlockedUsers(response.data.Items || []); // Assumes blocked users are in `Items`
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch blocked users.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchBlockedUsers();
    }
  }, [open]);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Blocked Users"
      searchPlaceholder="Search blocked users..."
      emptyMessage="No blocked users to display."
      data={blockedUsers}
      isLoading={isLoading}
      errorMessage={error}
    />
  );
};

BlockUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default BlockUserDialog;
