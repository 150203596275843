import axios from 'axios';

// Base URLs for different services
const API_BASE_URLS = {
  base:process.env.REACT_APP_API_BASE_URL,
  general: process.env.REACT_APP_API_GENERAL_SERVICE,
  // auth: process.env.REACT_APP_API_AUTH_SERVICE,
  // user: process.env.REACT_APP_API_USER_SERVICE,
  // otherService: process.env.REACT_APP_API_OTHER_SERVICE,
};

// Function to handle unauthorized access, clearing token, and redirecting to login
const handleUnauthorized = () => {
  console.warn("Unauthorized access. Redirecting to login...");
  localStorage.removeItem("token");
  window.location.href = '/';
};

// Centralized error handling function
const handleApiError = (error) => {
  console.error('API Error encountered:', error);

  if (error.response) {
    const status = error.response.status;
    const errorMessage = error.response.data?.message || error.message || "An error occurred.";

    if (status === 401 || errorMessage === "Authorization token not found") {
      handleUnauthorized();
    } else {
      console.error(`API Error (Status ${status}):`, errorMessage);
      throw new Error(errorMessage);
    }
  } else if (error.request) {
    console.error("No response received from API:", error.message);
    throw new Error("Network error. Please check your connection.");
  } else {
    console.error("Unexpected error:", error.message);
    throw error;
  }
};

// Function to generate Axios config with Authorization token and custom headers
const generateConfig = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.warn("Authorization token is missing. Redirecting to login...");
    handleUnauthorized();
    throw new Error("Authorization token is missing.");
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  };
};

// Helper to get the base URL for a specific service
const getBaseUrl = (serviceType) => {
  const baseUrl = API_BASE_URLS[serviceType];
  if (!baseUrl) {
    throw new Error(`Base URL not configured for service type: ${serviceType}`);
  }
  return baseUrl;
};

// Utility function for GET requests
export const getResponse = async (serviceType, endpoint) => {
  try {
    const baseUrl = getBaseUrl(serviceType);
    const config = generateConfig();
    const response = await axios.get(`${baseUrl}/${endpoint}`, config);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// Utility function for GET by ID requests
export const getById = async (serviceType, endpoint, id) => {
  try {
    const baseUrl = getBaseUrl(serviceType);
    const config = generateConfig();
    const response = await axios.get(`${baseUrl}/${endpoint}/${id}`, config);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// Utility function for POST requests
export const postResponse = async (serviceType, endpoint, data) => {
  try {
    const baseUrl = getBaseUrl(serviceType);
    const config = generateConfig();
    const response = await axios.post(`${baseUrl}/${endpoint}`, data, config);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// Utility function for PUT requests
export const putResponse = async (serviceType, endpoint, data) => {
  try {
    const baseUrl = getBaseUrl(serviceType);
    const config = generateConfig();
    const response = await axios.put(`${baseUrl}/${endpoint}`, data, config);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// Utility function for DELETE requests
export const deleteResponse = async (serviceType, endpoint) => {
  try {
    const baseUrl = getBaseUrl(serviceType);
    const config = generateConfig();
    const response = await axios.delete(`${baseUrl}/${endpoint}`, config);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};
