
export const languageColumns = [
  {
    accessorKey: "image",
    header: "Image",
    Cell: ({ cell }) => (
      <img
        src={cell.getValue()}
        alt="Language"
        style={{ width: "40px", height: "40px", borderRadius: "50%" }}
      />
    ),
  },
  {
    accessorKey: "name",
    header: "Language Name",
  },
  {
    accessorKey: "lnCode",
    header: "Language Code",
  },
  {
    accessorKey: "translation",
    header: "Translation",
  },
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell }) => (
      <span
        className={`status ${
          cell.getValue() === 1 ? "active" : "inactive"
        }`}
      >
        {cell.getValue() === 1 ? "Active" : "Inactive"}
      </span>
    ),
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    Cell: ({ cell }) =>
      new Date(cell.getValue()).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
  },
  {
    accessorKey: "updatedAt",
    header: "Updated At",
    Cell: ({ cell }) =>
      new Date(cell.getValue()).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
  },
 
];


