import React from "react";
import { Avatar, Chip, Box, Tooltip, IconButton } from "@mui/material";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";

export const mostFollowedUsersColumns = [
    {
        accessorKey: "profilePicture", //access nested data with dot notation
        header: "Profile Picture",
        size: 100,
        Cell: ({ cell }) => (
          <div>
            <Avatar src={cell.getValue()} sx={{ width: 30, height: 30 }} />
          </div>
        ),
      }, 
    {
      accessorKey: "userName",
      header: "Username",
      Cell: ({ cell }) => <span>{cell.getValue() || "N/A"}</span>,
    },
    {
        accessorKey: "fullName",
        header: "Full Name",
        Cell: ({ row }) => {
          const firstName = row.original.firstName || "N/A";
          const lastName = row.original.lastName || "N/A";
          return <span>{`${firstName} ${lastName}`.trim()}</span>;
        },
      },
      
    {
      accessorKey: "email",
      header: "Email",
      Cell: ({ cell }) => <span>{cell.getValue() || "N/A"}</span>,
    },
    {
      accessorKey: "totalFollowers",
      header: "Followers",
      Cell: ({ cell }) => <span>{cell.getValue() || 0}</span>,
    },
    {
      accessorKey: "totalFollowing",
      header: "Following",
      Cell: ({ cell }) => <span>{cell.getValue() || 0}</span>,
    },
    {
      accessorKey: "totalVideos",
      header: "Total Videos",
      Cell: ({ cell }) => <span>{cell.getValue() || 0}</span>,
    },
   

{
    accessorKey: 'status',
    header: 'Status',
    size: 100,
    Cell: ({ cell }) => {
      const status = cell.getValue(); // Get the status value
      const statusText = status === 1 ? 'Active' : 'Inactive'; // Map status to text
      const chipColor = status === 1 ? 'success' : 'error'; // Dynamic color
  
      return (
        <Chip
          label={statusText} // Set the label dynamically
          color={chipColor} // Use the mapped color
          size="small"
          variant="outlined"
        />
      );
    },
  },
    {
      accessorKey: "dob",
      header: "Date of Birth",
      Cell: ({ cell }) => {
        const dob = cell.getValue();
        const formattedDob =
          typeof dob === "number"
            ? new Date(dob).toLocaleDateString()
            : dob || "N/A";
        return <span>{formattedDob}</span>;
      },
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      Cell: ({ cell }) => {
        const createdAt = cell.getValue();
        return createdAt
          ? new Date(createdAt).toLocaleDateString()
          : "N/A";
      },
    },
    {
      accessorKey: "lastLoginAt",
      header: "Last Login",
      Cell: ({ cell }) => {
        const lastLoginAt = cell.getValue();
        return lastLoginAt
          ? new Date(lastLoginAt).toLocaleDateString()
          : "N/A";
      },
    },
   
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: 1 }}>  
        <Tooltip title="View">
          <Link  to={`/customers/${row.original.id}`}>
            <IconButton>
              <FiEye />
            </IconButton>
            
          </Link>
        </Tooltip>
     
    </Box>
      ),
    },


   
  ];
  