import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Card as MuiCard, CssBaseline,CircularProgress, FormControl, FormLabel, IconButton, TextField, Typography,  Grid} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { MdLocalPhone } from 'react-icons/md';
import { styled } from '@mui/system';
import logo from '../../../assets/images/logo.png';

// Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: 10,
  padding: theme.spacing(3),
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('sm')]: { maxWidth: 400 },
  [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
  boxShadow: theme.palette.mode === 'dark'? '0px 5px 15px rgba(0,0,0,0.5)' : '0px 5px 15px rgba(0,0,0,0.1)',
}));

const OTPContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background: theme.palette.mode === 'dark' ? 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))'  : 'radial-gradient(circle, #e0f7fa, #ffffff)',
}));

const VerifyForgetPasswordOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isResendVisible, setIsResendVisible] = useState(false);

  const token = location.state?.token;
  const email = location.state?.email;

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => setTimer(prev => prev - 1), 1000);
      return () => clearInterval(countdown)}
    setIsResendVisible(timer === 0);
  },[timer]);


  const handleOtpChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  const validateOtp = () => {
    if (otp.length !== 6) {
      toast.error('OTP must be a 6-digit number');
      return false;
    }
    return true;
  };

  const handleVerifyOtp = async () => {
    if (!validateOtp()) return;
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/verifyforgetPasswordOTP`,
        { forgetPasswordToken: token, otp: Number(otp) },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.status === 201) {
        const newToken = response.headers['token'];
        toast.success('OTP verified successfully!');
        navigate('/reset-password', { state: { token: newToken } });
      } else {
        toast.error('OTP verification failed. Please try again.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setIsResendVisible(false);
    setTimer(30);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/forgetPassword`,
        { email },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.status === 201) {
        setOtp('');
        const newToken = response.headers['token'];
        toast.success('OTP resent successfully! Please check your email.');
        navigate('/verify-forgot-password-otp', { state: { token: newToken, email } });
      } else {
        toast.error('Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'An error occurred while resending OTP.';
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <CssBaseline />
      <OTPContainer>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={6}>
            <Card>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo} alt="Logo" style={{ width: 100, height: 'auto' }} />
              </Box>
              <Typography variant="h6" fontWeight="bold" align="center">
                Enter OTP
              </Typography>
              <Typography color="textSecondary" align="center">
                Enter the 6-digit OTP sent to your email: <strong>{email}</strong>
              </Typography>
              <FormControl fullWidth>
                <FormLabel htmlFor="otp">OTP</FormLabel>
                <TextField
                  id="otp"
                  variant="outlined"
                  value={otp}
                  onChange={handleOtpChange}
                  inputProps={{ maxLength: 6 }}
                  placeholder="Enter OTP"
                  type="number"
                  size="small"
                  required
                  InputProps={{
                    startAdornment: (
                      <IconButton edge="start" disabled>
                        <MdLocalPhone />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                fullWidth
                onClick={handleVerifyOtp}
                disabled={otp.length !== 6 || loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify OTP'}
              </Button>
              <Typography variant="body2" align="center">
             {isResendVisible ? (
              <span>
              Didn’t receive the OTP?{' '}
              <Button
                onClick={handleResendOtp}
                variant="text"
                size="small"
                color="primary"
              >
                Resend OTP
              </Button>
            </span>
            ) : (
              <Typography variant="body2" color="textSecondary">
              Didn’t receive the OTP? You can resend in{' '}
              {`00:${timer < 10 ? `0${timer}` : timer}`} seconds.
            </Typography>
           )}
          </Typography>
            </Card>
          </Grid>
        </Grid>
      </OTPContainer>
    </>
  );
};
export default VerifyForgetPasswordOTP;
