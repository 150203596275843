import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/useAuth";
import { CircularProgress, Box } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  // 1. Show a loading indicator while authentication status is being checked
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full screen height for centered loader
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // 2. Redirect to login if the user is not authenticated
  // Pass the current location so the user can return after logging in
  return isAuthenticated ? ( children ) : ( <Navigate to="/login" replace state={{ from: location }} />);
};

export default ProtectedRoute;


// import React from "react";
// import { Navigate } from "react-router-dom";
// import axios from "axios";

// const ProtectedRoute = ({ children }) => {
//     const token = localStorage.getItem("token") || sessionStorage.getItem("token");
//     if (token) {
//         axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     }
    // if (!token) {
    //     return <Navigate to="/login" />;
    // }

//     return children;
// };

// export default ProtectedRoute;