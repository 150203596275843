import React from "react";
import {
  Avatar,
  Box,
  Paper,
  Typography,
  Stack,
  Chip,
  Grid,
  Alert
} from "@mui/material";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BlockIcon from "@mui/icons-material/Block";
import { ProfileSkeleton } from "../../../components/common/loader/Loader";

// Helper function to determine device type based on device ID
const getDeviceType = (deviceId) => {
  if (!deviceId) return "Unknown";
  if (deviceId.startsWith("APA91")) return "Android";
  if (deviceId.startsWith("dGqm") || deviceId.includes(":")) return "iOS";
  return "Web";
};

// UserStats Component
const UserStats = ({ totalVideos, totalFollowers, totalFollowing, onOpenDialog }) => (
  <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 4 }} sx={{ mb: 3 }}>
    <StatItem icon={<VideoLibraryIcon color="primary" />} label={`${totalVideos || 0} posts`} />
    <StatItem
      icon={<GroupIcon color="primary" />}
      label={`${totalFollowers || 0} followers`}
      onClick={() => onOpenDialog("followers")}
    />
    <StatItem
      icon={<PersonAddIcon color="primary" />}
      label={`${totalFollowing || 0} following`}
      onClick={() => onOpenDialog("following")}
    />
    <StatItem
      icon={<BlockIcon color="error" />}
      label="Block user"
      onClick={() => onOpenDialog("blockuser")}
      isError
    />
  </Stack>
);

// StatItem Component
const StatItem = ({ icon, label, onClick, isError }) => (
  <Stack direction="row" alignItems="center" spacing={1} onClick={onClick} sx={{ cursor: onClick ? "pointer" : "default" }}>
    {icon}
    <Typography variant="body1" color={isError ? "error" : "inherit"}>
      <strong>{label}</strong>
    </Typography>
  </Stack>
);

const ProfileSection = ({ userData, onOpenDialog, loading }) => {
  if (loading) {
    return <ProfileSkeleton />;
  }

  // Check if userData is null or undefined
  if (!userData) {
    return (
      <Box sx={{ textAlign: "center", pt: "80px" }}>
        <Typography variant="h6" >
        <Alert>User data not available.</Alert>  
        </Typography>
      </Box>
    );
  }

const { firstName, lastName,email, phone,dob, userName,profilePicture, totalVideos, totalFollowing, totalFollowers, status, createdAt,lastLoginAt,deviceId} = userData;

  const deviceType = getDeviceType(deviceId);

  return (
    <Box sx={{ pb: "10px" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        User Details
      </Typography>
      <Paper
        sx={{
          boxShadow: "none",
          borderRadius: "12px",
          border: "1px solid",
          borderColor: "divider",
          p: 3,
        }}
      >
        <Grid container spacing={3}>
          {/* Avatar Section */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Avatar
                src={profilePicture}
                alt={userName}
                sx={{ width: { xs: "120px", sm: "150px" }, height: { xs: "120px", sm: "150px" }, }} />
            </Box>
          </Grid>
          {/* User Info Section */}
          <Grid item xs={12} md={8}>
            <UserStats
              totalVideos={totalVideos}
              totalFollowers={totalFollowers}
              totalFollowing={totalFollowing}
              onOpenDialog={onOpenDialog} />
            {/* User Details */}
            <Typography variant="body1" sx={{ mb: 1 }}>
              Username: <strong>{userName || "N/A"}</strong>
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Full Name: <span style={{ opacity: 0.7 }}>{`${firstName || "N/A"} ${lastName || ""}`}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Email: <span style={{ opacity: 0.7 }}>{email || "N/A"}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Phone: <span style={{ opacity: 0.7 }}>{phone || "N/A"}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              DOB: <span style={{ opacity: 0.7 }}>{dob ? new Date(dob).toLocaleDateString() : "N/A"}</span>
            </Typography>

            {/* Status */}
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Status:{" "}
              <Chip
                label={status === 1 ? "Active" : "Inactive"}
                color={status === 1 ? "success" : "error"}
                size="small" />
            </Typography>

            {/* Additional Info */}
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Created At: <span style={{ opacity: 0.7 }}>{createdAt ? new Date(createdAt).toLocaleString() : "N/A"}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Last Login At: <span style={{ opacity: 0.7 }}>{lastLoginAt ? new Date(lastLoginAt).toLocaleString() : "N/A"}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Device Type: <span style={{ opacity: 0.7 }}>{deviceType || "N/A"}</span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ProfileSection;