import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import Table from "../../components/common/table/Table";
import { hashtagColumns } from "../../data/hastags";
import useHashtags from "./hooks/useHashtags";

// Styled wrapper for consistent layout.
const ComponentWrapper = styled(Box)({
  marginTop: "10px",
  paddingBottom: "10px",
});

/**
 * Component to display hashtags table.
 */
const Hashtags = () => {
  const { hashtags, loading } = useHashtags();

  return (
    <Box sx={{ pt: "60px", pb: "20px" }}>
      <ComponentWrapper>
        <Typography variant="h6" sx={{ my: 3 }}>
          Hashtags
        </Typography>
        <Table
          isLoading={loading}
          data={hashtags}
          fields={hashtagColumns}
          numberOfRows={5}
          enableTopToolBar={false}
          enableBottomToolBar={false}
          enablePagination={false}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableEditing={false}
          enableColumnDragging={false}
        />
      </ComponentWrapper>
    </Box>
  );
};

export default Hashtags;
