import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { Loader } from "../components/common/loader/Loader";

// Create AuthContext
const AuthContext = createContext();

// Hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// Token Management Functions
const getToken = () => localStorage.getItem("token");

const setToken = (token) => {
  localStorage.setItem("token", token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const clearToken = () => {
  localStorage.removeItem("token");
  delete axios.defaults.headers.common["Authorization"];
};

// Check if the token is expired
const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // Treat as expired if there's an error
  }
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Check authentication on mount and route change
  useEffect(() => {
    const checkAuth = () => {
      const publicRoutes = ["/login", "/verify-forgot-password-otp", "/reset-password"];
      const token = getToken();

      // Allow access to public routes without token check
      if (publicRoutes.includes(location.pathname)) {
        setLoading(false);
        return;
      }

      // Token check for protected routes
      if (token && !isTokenExpired(token)) {
        setToken(token);
        setIsAuthenticated(true);
      } else {
        clearToken();
        setIsAuthenticated(false);
        navigate("/login", { replace: true });
      }

      setLoading(false);
    };

    checkAuth();
  }, [location.pathname, navigate]);

  // Login function
  const login = (token) => {
    try {
      setToken(token);
      setIsAuthenticated(true);
      toast.success("Login successful!");
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Login failed.");
      clearToken();
      setIsAuthenticated(false);
    }
  };

  // Logout function
  const logout = () => {
    try {
      clearToken();
      setIsAuthenticated(false);
      toast.success("Logged out successfully!");
      navigate("/login", { replace: true });
    } catch (error) {
      console.error("Error during logout:", error);
      toast.error("Logout failed.");
    }
  };

  // Show loader while checking authentication status
  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};










// import React, { createContext, useContext, useState, useEffect } from "react";
// import { toast } from "react-toastify";

// const AuthContext = createContext();

// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     setIsAuthenticated(!!token);
//     setLoading(false);
//   }, []);

//   const login = (token) => {
//     localStorage.setItem("token", token);
//     setIsAuthenticated(true);
//     toast.success("Login successful!");
//   };

//   const logout = () => {
//     localStorage.removeItem("token");
//     setIsAuthenticated(false);
//     toast.success("Logged out successfully!");
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
