import React, { useState, useEffect } from "react";
import { Snackbar, Alert, Box, Typography } from "@mui/material";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setSnackbarOpen(true);
      // Automatically reload the current page when back online
      setTimeout(() => {
        window.location.reload(); // Reload the current page
      }, 1000); // Add a small delay before reload
    };

    const handleOffline = () => {
      setIsOnline(false);
      setSnackbarOpen(true);
    };

    // Add event listeners for online/offline events
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {/* Persistent offline banner */}
      {!isOnline && (
        <Box
          sx={{
            width: "100%",
            py: 1,
            px: 2,
            backgroundColor: "error.main",
            color: "white",
            textAlign: "center",
            position: "fixed",
            top: 0,
            zIndex: 1200,
          }}
        >
          <Typography variant="body2">
            You are offline. Some features may not work.
            <Typography
              variant="contained"
              sx={{  border: 2,   p: 0.5,  borderRadius: 6,  cursor: "pointer",  display: "inline-block", marginLeft: 2, }}
              onClick={() => window.location.reload()}  >
              Retry Connection
            </Typography>
          </Typography>
        </Box>
      )}

      {/* Snackbar notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          severity={isOnline ? "success" : "error"}
          onClose={() => setSnackbarOpen(false)}
          sx={{ width: "100%" }} >
          {isOnline ? "You are back online! Reloading the page..." : "You are offline. Check your internet connection."}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NetworkStatus;


// import React, { useState, useEffect } from "react";
// import { Snackbar, Alert, Box, Typography } from "@mui/material";
// // import {
// //   Wifi as WifiIcon,
// //   SignalCellularAlt as CellularIcon,
// //   SignalWifiOff as OfflineIcon,
// // } from "@mui/icons-material";

// const NetworkStatus = () => {
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);

//   useEffect(() => {
//     const handleOnline = () => {
//       setIsOnline(true);
//       setSnackbarOpen(true);
//     };

//     const handleOffline = () => {
//       setIsOnline(false);
//       setSnackbarOpen(true);
//     };

//     // Add event listeners for online/offline events
//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     // Cleanup event listeners on unmount
//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   return (
//     <>
//       {/* Persistent offline banner */}
//       {!isOnline && (
//         <Box
//           sx={{
//             width: "100%",
//             py: 1,
//             px: 2,
//             backgroundColor: "error.main",
//             color: "white",
//             textAlign: "center",
//             position: "fixed",
//             top: 0,
//             zIndex: 1200,
//           }}
//         >
//           <Typography variant="body2">
//           {/* {isOnline ? <WifiIcon /> : <OfflineIcon />}   */}
//            You are offline. Some features may not work?.
//             <Typography
//                   variant="contained"
                 
//                   sx={{border:2,p:0.5,borderRadius:6,cursor:"pointer"}}
//                   onClick={() => window.location.reload()}
//                 >
//                   Retry Connection
//                 </Typography>
//           </Typography>
//         </Box>
//       )}

//       {/* Snackbar notification */}
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={3000}
//         onClose={() => setSnackbarOpen(false)}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           severity={isOnline ? "success" : "error"}
//           onClose={() => setSnackbarOpen(false)}
//           sx={{ width: "100%" }}
//         >
//           {isOnline ? "You are back online!" : "You are offline. Check your internet connection."}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// export default NetworkStatus;


