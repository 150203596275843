// import { useEffect } from 'react';

// const useRememberMe = (loginData, setLoginData) => {
//   const toggleRememberMe = (rememberMe, email, password) => {
//     if (rememberMe) {
//       localStorage.setItem('rememberEmail', email);
//       localStorage.setItem('rememberPassword', password);
//       localStorage.setItem('rememberMe', 'true');
//     } else {
//       localStorage.removeItem('rememberEmail');
//       localStorage.removeItem('rememberPassword');
//       localStorage.removeItem('rememberMe');
//     }
//   };

//   useEffect(() => {
//     const remember = localStorage.getItem('rememberMe') === 'true';
//     if (remember) {
//       setLoginData({
//         email: localStorage.getItem('rememberEmail') || '',
//         password: localStorage.getItem('rememberPassword') || '',
//       });
//     }
//   }, [setLoginData]);

//   return { toggleRememberMe };
// };

// export default useRememberMe;



import { useEffect } from 'react';

const useRememberMe = (setLoginData) => {
  // Function to handle storing and removing "Remember Me" credentials
  const toggleRememberMe = (rememberMe, email, password) => {
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('rememberEmail', email);
      localStorage.setItem('rememberPassword', password);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('rememberEmail');
      localStorage.removeItem('rememberPassword');
    }
  };

  // Load remembered credentials on component mount
  useEffect(() => {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    if (rememberMe) {
      const email = localStorage.getItem('rememberEmail') || '';
      const password = localStorage.getItem('rememberPassword') || '';
      setLoginData({ email, password });
    }
  }, [setLoginData]);

  return { toggleRememberMe };
};

export default useRememberMe;
