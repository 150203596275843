import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Card as MuiCard,
  CssBaseline,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import logo from "../../../assets/images/logo.png";
import { RiLockPasswordFill } from "react-icons/ri";

// Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  borderRadius: 10,
  padding: theme.spacing(3),
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: { maxWidth: 400 },
  [theme.breakpoints.down("sm")]: { padding: theme.spacing(2) },
  boxShadow:
    theme.palette.mode === "dark"
      ? "0px 5px 15px rgba(0,0,0,0.5)"
      : "0px 5px 15px rgba(0,0,0,0.1)",
}));

const RESETContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  background:
    theme.palette.mode === "dark"
      ? "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"
      : "radial-gradient(circle, #e0f7fa, #ffffff)",
}));

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const token = location.state?.token;

  const validatePassword = (value) => {
    if (value.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  };
  const validateConfirmPassword = (value) => {
    if (value !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    validatePassword(value);
  };
  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    validateConfirmPassword(value);
  };

  const isFormValid = () =>
    password.length >= 8 &&
    confirmPassword.length >= 8 &&
    password === confirmPassword;
   const handleResetPassword = async () => {
    if (!isFormValid()) {
      toast.error("Please fix the errors before proceeding.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post( `${process.env.REACT_APP_API_BASE_URL}/admin/resetPassword`,
        { resetPasswordToken: token, password },
        { headers: { "Content-Type": "application/json" }});
      if (response.status === 201) {
        toast.success(   "Password reset successfully! Please login with your new password." );
        navigate("/login");
      } else {
        toast.error("Password reset failed. Please try again.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <CssBaseline />
      <RESETContainer>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={6}>
            <Card>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} alt="Logo" style={{ width: "100px" }} />
              </Box>
              <Typography variant="h6" fontWeight="bold" align="center">
                Reset Password
              </Typography>
              <FormControl fullWidth>
                <FormLabel htmlFor="password">New Password</FormLabel>
                <TextField
                  id="password"
                  variant="outlined"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter new password"
                  type="password"
                  error={!!passwordError}
                  helperText={passwordError}
                  required
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <IconButton edge="start" disabled>
                        <RiLockPasswordFill />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                <TextField
                  id="confirmPassword"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Re-enter your new password"
                  type="password"
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  required
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <IconButton edge="start" disabled>
                        <RiLockPasswordFill />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                fullWidth
                onClick={handleResetPassword}
                disabled={!isFormValid() || loading} >
                {loading ? (<CircularProgress size={24} color="inherit" /> ) : ( "Reset Password"  )}
              </Button>
              <Typography variant="body2" align="center">
                <Button
                  variant="text"
                  onClick={() => navigate("/login")}
                  size="small"
                  color="primary">
                  Back to Login
                </Button>
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </RESETContainer>
    </>
  );
};
export default ResetPassword;
