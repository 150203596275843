import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

/**
 * Custom hook to fetch and manage video data.
 * @param {string} route - The API route for fetching videos.
 * @returns {object} - Contains video data, loading state, and play/pause handlers.
 */

const useVideos = (route) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const isFetched = useRef(false); // Track if the API call has already been made

  useEffect(() => {
    // Prevent multiple API calls
    if (isFetched.current) return;
    isFetched.current = true;

    const fetchVideos = async () => {
      setLoading(true);
      try {
        const serviceType = "base";
        const response = await getResponse(serviceType, route);
        if (response.status === 200) {
          const data = response.data || []; // Ensure data is always an array
          setVideos(data);
        } else {
          console.error(`API Error: Received status ${response?.status}`);
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching videos:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [route]); // Rerun effect if `route` changes


  const handlePlayPause = (id) => {
    setPlayingVideoId((prevId) => (prevId === id ? null : id));
  };

  return { videos, loading, playingVideoId, handlePlayPause };
};

export default useVideos;
