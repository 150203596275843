import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useSignInForm = ({ onSuccess }) => {
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const validateForm = () => {
    const newErrors = {};
    if (!loginData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!loginData.password || loginData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setApiError('');

    try {
      const { headers } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/login`,
        {
          userName: loginData.email,
          password: loginData.password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const token = headers['token'];
      if (token) {
        onSuccess(token);
        toast.success('Login successful');
      } else {
        throw new Error('Token not received from server.');
      }
    } catch (error) {
      const errorMessage =
      error.response?.data?.message || error.message || 'Login failed';
      setApiError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return {
    loginData,
    errors,
    loading,
    apiError,
    handleInputChange,
    handleSubmit,
  };
};

export default useSignInForm;
