import React, { memo } from "react";
import styled from "@emotion/styled";
import { Box, Grid, Paper, Typography, IconButton, Alert } from "@mui/material";
import { FaUser, FaVideo } from "react-icons/fa";
import useStats from "../../hooks/useStats";
import { CardLoader } from "../../../../components/common/loader/Loader";

// Styled Card Component
const StyledCard = styled(Paper)(({ theme }) => ({
  padding: "20px",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
  },
}));

const Stats = memo(() => {
  const { statsData, loading } = useStats();
  // Define stats array based on the fetched data
  const stats = [
    {
      id: 1,
      title: "Total Users",
      totalNumber: statsData?.totalUsers || 0,
      icon: <FaUser size={30} />,
      iconBg: "#e3f2fd",
      iconColor: "#1976d2",
    },
    {
      id: 2,
      title: "Total Videos",
      totalNumber: statsData?.totalVideos || 0,
      icon: <FaVideo size={30} />,
      iconBg: "#fff3e0",
      iconColor: "#ff9800",
    },
  ];

  if (loading) {
    return <CardLoader />;
  }

 

  return (
    <Box>
      <Grid container spacing={4}>
        {stats.map(({ id, title, totalNumber, icon, iconBg, iconColor }) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
            <StyledCard>
              <IconButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: iconBg,
                  color: iconColor,
                  width: "64px",
                  height: "64px",
                  flexShrink: 0,
                  "&:hover": {
                    backgroundColor: iconBg,
                  },
                }}
              >
                {icon}
              </IconButton>
              <Box sx={{ marginLeft: "16px", textAlign: "left" }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "8px",
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                  }}
                >
                  {totalNumber}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    color: "text.secondary",
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default Stats;
