import { Paper, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Paper
      sx={{
        boxShadow: "none !important",
        borderRadius: "12px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "divider",
        py: "10px",
        mb: "20px",
        textAlign: "center",
      }}
    >
      <Typography>
        Created by{' '}
        <a
          href="https://lalitmittaltechnologies.com/"
          target="_blank"
          rel="noopener noreferrer" // Added for security
          style={{ color: "#027edd", fontWeight: "bold" }}
        >
          LMT
        </a>{' '}
        | Bloopers Admin Dashboard. All Rights Reserved &copy; {new Date().getFullYear()}
      </Typography>
    </Paper>
  );
};

export default Footer;