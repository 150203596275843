import { useColorTheme } from '../../../../contexts/ThemeContext';

const useThemeToggle = () => {
  const colorMode = useColorTheme();

  const toggleTheme = () => {
    colorMode.toggleColorMode();
  };

  return { toggleTheme };
};

export default useThemeToggle;
