// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";

// const VideoUnassigned = () => {
//   const [videos, setVideos] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [nextKey, setNextKey] = useState(null);

//   const API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/list/video/unassigned";

//   // Fetch videos from the API
//   const fetchVideos = async (pageKey = null) => {
//     setLoading(true);
//     setError(null);
//     try {
//       // Retrieve token from localStorage
//       const token = localStorage.getItem("token");

//       if (!token) {
//         throw new Error("Authentication token is missing. Please log in.");
//       }

//       const response = await axios.get(API_URL, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         params: {
//           pageSize: 1,
//           nextKey: pageKey,
//         },
//       });

//       const { list, isNext, nextkey } = response.data;
//       setVideos((prevVideos) => [...prevVideos, ...list]);
//       setNextKey(isNext ? nextkey : null);
//     } catch (err) {
//       setError(err.message || "An error occurred while fetching videos.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     // Initial fetch
//     fetchVideos();
//   }, []);

//   return (
//     <Box p={4}>
//       <Typography variant="h4" gutterBottom>
//         Video List
//       </Typography>

//       {loading && (
//         <Box display="flex" justifyContent="center" my={2}>
//           <CircularProgress />
//         </Box>
//       )}

//       {error && (
//         <Typography color="error" gutterBottom>
//           {error}
//         </Typography>
//       )}

//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Key</TableCell>
//               <TableCell>Last Modified</TableCell>
//               <TableCell>Size</TableCell>
//               <TableCell>Storage Class</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {videos.map((video, index) => (
//               <TableRow key={index}>
//                 <TableCell>{video.Key}</TableCell>
//                 <TableCell>{new Date(video.LastModified).toLocaleString()}</TableCell>
//                 <TableCell>{video.Size} bytes</TableCell>
//                 <TableCell>{video.StorageClass}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {nextKey && (
//         <Box display="flex" justifyContent="center" mt={2}>
//           <Button variant="contained" onClick={() => fetchVideos(nextKey)} disabled={loading}>
//             Load More
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default VideoUnassigned;
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Grid,
//   Card,
//   CardMedia,
//   CircularProgress,
//   Typography,
//   Button,
// } from "@mui/material";

// const VideoList = () => {
//   const [videos, setVideos] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [nextKey, setNextKey] = useState(null);
//   const [prevKeys, setPrevKeys] = useState([]);
//   const [isNext, setIsNext] = useState(false);

//   const API_URL =
//     "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/list/video/unassigned";
//   const VIDEO_BASE_URL =
//     "https://bloopers-unassigned-videos.s3.ap-south-1.amazonaws.com/";

//   // Fetch videos from the API
//   const fetchVideos = async (pageKey = null) => {
//     setLoading(true);
//     setError(null);

//     try {
//       // Retrieve token from localStorage
//       const token = localStorage.getItem("token");

//       if (!token) {
//         throw new Error("Authentication token is missing. Please log in.");
//       }

//       const response = await axios.get(API_URL, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         params: {
//           pageSize: 3,
//           nextKey: pageKey, // 
//         },
//       });

//       const { list, isNext, nextkey } = response.data;

//       // Track previous keys for backward navigation
//       if (pageKey && !prevKeys.includes(pageKey)) {
//         setPrevKeys([...prevKeys, pageKey]);
//       }
//       setVideos(list); // Update videos array
//       setIsNext(isNext); // Update `isNext`
//       setNextKey(nextkey); // Update `nextKey`
  
//       // Log the updated state
//       console.log("Updated videos:", list);
//       console.log("Updated nextKey:", nextkey);
//       console.log("Updated prevKeys:", prevKeys);
//     } catch (err) {
//       setError(err.response?.data?.message || "An error occurred while fetching videos.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     // Initial fetch
//     fetchVideos();
//   }, []);

//   const loadPreviousVideos = () => {
//     if (prevKeys.length > 1) {
//       const previousKey = prevKeys[prevKeys.length - 2];
//       setPrevKeys(prevKeys.slice(0, -1));
//       fetchVideos(previousKey);
//     }
//   };

//   const loadNextVideos = () => {
//     if (nextKey) {
//       fetchVideos(nextKey);
//     }
//   };

//   return (
//     <Box p={4} mt={6}>
      // <Typography variant="h5" gutterBottom>
      //   Video Gallery
      // </Typography>

//       {loading && (
//         <Box display="flex" justifyContent="center" my={2}>
//           <CircularProgress />
//         </Box>
//       )}

//       {error && (
//         <Typography color="error" gutterBottom>
//           {error}
//         </Typography>
//       )}

//       <Grid container spacing={3} mt={2}>
//         {videos.map((video, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card>
//               <CardMedia
//                 component="video"
//                 controls
//                 src={`${VIDEO_BASE_URL}${video.Key}`}
//                 title={video.Key}
//                 style={{ height: "200px" }}
//               />
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={loadPreviousVideos}
//           disabled={prevKeys.length <= 1 || loading}
//         >
//           Previous
//         </Button>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={loadNextVideos}
//           disabled={!isNext || loading}
//         >
//           Next
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default VideoList;



// Import necessary dependencies

// Import necessary dependencies


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Button,
//   Card,
//   CardMedia,
//   CircularProgress,
//   Typography,
//   Grid,
//   Box,
// } from "@mui/material";
// import { FiPlus } from "react-icons/fi";
// import { Link } from "react-router-dom";
// const API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/list/video/unassigned";
// const UPLOAD_API_URL = "https://hmllmmo5bj.execute-api.ap-south-1.amazonaws.com/dev/start";
// const VIDEO_BASE_URL = "https://bloopers-unassigned-videos.s3.ap-south-1.amazonaws.com/";

// const VideoPagination = () => {
//   const [videos, setVideos] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [nextKey, setNextKey] = useState(null);
//   const [isNext, setIsNext] = useState(true);
//   const [uploading, setUploading] = useState(false);

//   // Get token from localStorage
//   const token = localStorage.getItem("token");

//   // Fetch videos from the API
//   const fetchVideos = async (key = null) => {
//     setLoading(true);
//     try {
//       const response = await axios.get(API_URL, {
//         params: {
//           pageSize: 2,
//           nextKey: key,
//         },
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const { list, isNext: hasNext, nextkey } = response.data;
//       setVideos((prevVideos) => (key ? [...prevVideos, ...list] : list));
//       setIsNext(hasNext);
//       setNextKey(nextkey);
//     } catch (error) {
//       console.error("Error fetching videos:", error);
//       alert("Failed to load videos. Please try again later.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle video upload
//   const handleUpload = async () => {
//     setUploading(true);
//     try {
//       const response = await axios.get(UPLOAD_API_URL, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       const message = response.data?.message
//       alert(message); // Show the response message to the user
  
//       // if (message !== "No videos to upload") {
//       //   fetchVideos(); // Refresh the video list only if videos were uploaded
//       // }
//       // alert("Video uploaded successfully!");
//       fetchVideos(); // Refresh the video list after upload
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       alert("Failed to upload video. Please try again.");
//     } finally {
//       setUploading(false);
//     }
//   };

//   // Load videos on component mount
//   useEffect(() => {
//     fetchVideos();
//   }, []);

//   // Handle loading the next page
//   const handleNextPage = () => {
//     if (isNext && nextKey) {
//       fetchVideos(nextKey);
//     }
//   };

//   return (
//     <div style={{ padding: "20px", marginTop: "60px" }}>
//       {/* Header Section */}

//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           marginBottom: "16px",
//         }}
//       >
//         <Typography variant="h6">video</Typography>
//         <Box display="flex" gap={2}>
//     <Button
//      sx={{ borderRadius: "20px" }}
//       variant="contained"
//       color="primary"
//       onClick={handleUpload}
//       disabled={uploading}
//     >
//       {uploading ? "Loading..." : "Publish video"}
//     </Button>
//     <Link to="/video/uplaod" style={{ textDecoration: "none" }}>
//           <Button
//             variant="contained"
//             color="primary"
//             startIcon={<FiPlus />}
//             sx={{ borderRadius: "20px" }}
//           >
//             Uplaod video
//           </Button>
//         </Link>
//   </Box>
//       </Box>
 

//       {/* Loading Spinner or Video Grid */}
//       {loading ? (
//         <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "200px" }}>
//           <CircularProgress />
//         </Box>
//       ) : (
//         <div>
//           <Grid container spacing={2}>
//             {videos.map((video) => (
//               <Grid item xs={12} sm={6} md={4} key={video.Key}>
//                 <Card>
//                   <CardMedia
//                     component="video"
//                     src={`${VIDEO_BASE_URL}${video.Key}`}
//                     controls
//                     style={{ height: "200px" }}
//                   />
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>

//           {/* Load Next Button */}
//           {isNext && (
//             <Box textAlign="center" mt={2}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleNextPage}
//                 disabled={loading}
//               >
//                 Load Next
//               </Button>
//             </Box>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VideoPagination;






import React, { useState, useEffect } from "react";
import axios from "axios";
import {  Paper,  } from "@mui/material";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

const API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/list/video/unassigned";
const UPLOAD_API_URL = "https://hmllmmo5bj.execute-api.ap-south-1.amazonaws.com/dev/start";
const VIDEO_BASE_URL = "https://bloopers-unassigned-videos.s3.ap-south-1.amazonaws.com/";

const VideoPagination = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextKey, setNextKey] = useState(null);
  const [isNext, setIsNext] = useState(true);
  const [uploading, setUploading] = useState(false);

  const token = localStorage.getItem("token");

  // Fetch videos from the API
  const fetchVideos = async (key = null) => {
    setLoading(true);
    try {
      const encodedKey = key ? encodeURIComponent(key) : null;
      console.log("Encoded nextKey:", encodedKey); // Debugging log

      const response = await axios.get(API_URL, {
        params: {
          pageSize: 2,
          nextKey: encodedKey,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { list, isNext: hasNext, nextkey } = response.data;
      setVideos((prevVideos) => (key ? [...prevVideos, ...list] : list));
      setIsNext(hasNext);
      setNextKey(nextkey);
    } catch (error) {
      console.error("Error fetching videos:", error);
      alert("Failed to load videos. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Handle video upload
  const handleUpload = async () => {
    setUploading(true);
    try {
      const response = await axios.get(UPLOAD_API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const message = response.data?.message || "Upload complete.";
      alert(message);

      fetchVideos(); // Refresh the video list after upload
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Failed to upload video. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  // Load videos on component mount
  useEffect(() => {
    fetchVideos();
  }, []);

  // Handle loading the next page
  const handleNextPage = () => {
    if (isNext && nextKey) {
      fetchVideos(nextKey);
    }
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
    <Paper
      sx={{
        boxShadow: "none !important",
        borderRadius: "12px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "divider",
        p: "20px",
      }}
    >
 
    <>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Videos</Typography>
        <Box display="flex" gap={2}>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={uploading}
          >
            {uploading ? "Loading..." : "Publish Video"}
          </Button>
          <Link to="/video/upload" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
            >
              Upload Video
            </Button>
          </Link>
        </Box>
      </Box>

      {/* Loading Spinner or Video Grid */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "200px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Grid container spacing={2}>
            {videos.length > 0 ? (
              videos.map((video) => (
                <Grid item xs={12} sm={6} md={4} key={video.Key}>
                  <Card>
                    <CardMedia
                      component="video"
                      src={`${VIDEO_BASE_URL}${video.Key}`}
                      controls
                      style={{ height: "200px" ,borderRadius:"10px"}}
                    />
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>No videos available to display.</Typography>
            )}
          </Grid>

          {/* Load Next Button */}
          {isNext && videos.length > 0 && (
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextPage}
                disabled={loading}
              >
                Load Next
              </Button>
            </Box>
          )}
        </div>
      )}
    </>
    
    </Paper>
    </Box>
  );
};

export default VideoPagination;














