// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Grid,
//   Typography,
//   ToggleButton,
//   ToggleButtonGroup,
//   Paper,
//   CircularProgress,
// } from "@mui/material";
// import { toast } from "react-toastify";
// import { useParams } from "react-router-dom";
// import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
// import ProfileSection from "./pages/ProfileSection";
// import VideoTable from "./pages/VideoTable";
// import VideoGrid from "./pages/VideoGrid";
// import FileUpload from "./pages/FileUpload";
// import FollowersDialog from "../modal/FollowersDialog";
// import FollowingDialog from "../modal/FollowingDialog";
// import BlockUserDialog from "../modal/BlockUserDialog";

// const SingleUser = () => {
//   const { id } = useParams();
//   const [userData, setUserData] = useState(null);
//   const [userVideos, setUserVideos] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");
//   const [view, setView] = useState("table");
//   const [openDialog, setOpenDialog] = useState("");

//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         const userResponse = await axios.get(
//           `https://m4h5sy01i9.execute-api.ap-south-1.amazonaws.com/dev/user/profile/${id}`,
//           { headers: { Authorization: `Bearer ${token}` } }
//         );
//         setUserData(userResponse.data);

//         const videoResponse = await axios.get(
//           `https://m4h5sy01i9.execute-api.ap-south-1.amazonaws.com/dev/user/videos/${id}`,
//           { headers: { Authorization: `Bearer ${token}` } }
//         );
//         setUserVideos(videoResponse.data.Items || []);
//       } catch (err) {
//         setError("Failed to fetch data. Please try again.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [id, token]);

//   const handleViewChange = (event, newView) => {
//     if (newView) setView(newView);
//   };
  

//   const handleOpenDialog = (dialogType) => setOpenDialog(dialogType);
//   const handleCloseDialog = () => setOpenDialog("");

  


//   if (error) {
//     return (
//       <Box sx={{ textAlign: "center", pt: "80px" }}>
//         <Typography variant="h6" color="error">
//           {error}
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Grid item xs={12}>
//       <Box sx={{ pt: "80px", pb: "10px" }}>
//         <Breadcrumb />
//       </Box>

//       <ProfileSection
//         loading={loading}
//         userData={userData}
//         onOpenDialog={handleOpenDialog}
//       />
//       <Paper
//         sx={{
//           mt: 3,
//           mb: 2,
//           boxShadow: "none",
//           borderRadius: "12px",
//           border: "1px solid",
//           borderColor: "divider",
//         }}
//       >


//         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2 }}>
//           <Typography variant="h6" sx={{ fontSize: "16px", color: "#555" , fontWeight: "bold",}}>
//             Video List
//           </Typography>
//           <ToggleButtonGroup
//             value={view}
//             exclusive
//             onChange={handleViewChange}
//             aria-label="view toggle"
//             // sx={{
//             //   borderRadius: "8px",
//             //   boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
//             // }}
//             sx={{
//               border: 1 ,
             
//                 // borderColor: 'rgba(193, 193, 193, 0.8)',
//                 borderRadius: "8px",
//                 overflow: "hidden",
//                 "& .MuiToggleButton-root": {
//                   textTransform: "none",
//                   fontSize: "14px",
//                   fontWeight: "500",
//                   color: "#555",
//                   border: "none",
//                   borderRadius: "0", // Removes individual button borders
//                   padding: "6px 16px",
//                   "&.Mui-selected": {
//                     backgroundColor: "#1976d2",
//                     color: "#fff",
//                     boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
//                     fontWeight: "bold",
//                   },
                 
//                 },
//                 "& .MuiToggleButtonGroup-grouped": {
//                   borderRadius: "4px", // Grouped radius
//                   // margin: "0 2px",
//                 },
//               }}
//           >
//             <ToggleButton  value="table">Table View</ToggleButton>
//             <ToggleButton value="grid">Grid View</ToggleButton>
//             <ToggleButton value="upload">Upload Video</ToggleButton>
//           </ToggleButtonGroup>
//         </Box>
//       </Paper>

      

      // {view === "table" && <VideoTable videos={userVideos} />}
      // {view === "grid" && <VideoGrid videos={userVideos}  loading={loading}/>}
      // {view === "upload" && <FileUpload userId={id} token={token} />}


      // <FollowersDialog
      //   open={openDialog === "followers"}
      //   handleClose={handleCloseDialog}
      //   userId={id}
      //   token={token}
      // />
      // <FollowingDialog
      //   open={openDialog === "following"}
      //   handleClose={handleCloseDialog}
      //   userId={id}
      //   token={token}
      // />
      // <BlockUserDialog
      //   open={openDialog === "blockuser"}
      //   handleClose={handleCloseDialog}
      //   userId={id}
      //   token={token}
      // />
//     </Grid>
//   );
// };

// export default SingleUser;









import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import ProfileSection from "./pages/ProfileSection";
import VideoTable from "./pages/VideoTable";
import VideoGrid from "./pages/VideoGrid";
import FileUpload from "./pages/FileUpload";
import FollowersDialog from "./modal/FollowersDialog";
import FollowingDialog from "./modal/FollowingDialog";
import BlockUserDialog from "./modal/BlockUserDialog";
import useSingleUser from "./hook/useSingleUser";

const SingleUser = () => {
  const { id } = useParams();
  const {
    userData,
    userVideos,
    loading,
    error,
    handleUploadSuccess,
  } = useSingleUser();

  const [view, setView] = useState("table");
  const [openDialog, setOpenDialog] = useState("");

  const handleViewChange = (event, newView) => {
    if (newView) setView(newView);
  };

  const handleOpenDialog = (dialogType) => setOpenDialog(dialogType);
  const handleCloseDialog = () => setOpenDialog("");

  if (error) {
    return (
      <Box sx={{ textAlign: "center", pt: "80px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid item xs={12}>
      <Box sx={{ pt: "80px", pb: "10px" }}>
        <Breadcrumb />
      </Box>

      <ProfileSection
        loading={loading}
        userData={userData}
        onOpenDialog={handleOpenDialog}
      />

      <Paper
        sx={{
          mt: 3,
          mb: 2,
          boxShadow: "none",
          borderRadius: "12px",
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", color: "#555", fontWeight: "bold" }}
          >
            Video List
          </Typography>

          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view toggle"
            sx={{
              border: 1,
              borderColor: "#ccc",
              borderRadius: "8px",
              "& .MuiToggleButton-root": {
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "500",
                color: "#555",
                padding: "6px 16px",
                "&:hover": { backgroundColor: "#e0e0e0" },
                "&.Mui-selected": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontWeight: "bold",
                },
              },
            }}
          >
            <ToggleButton value="table">Table View</ToggleButton>
            <ToggleButton value="grid">Grid View</ToggleButton>
            <ToggleButton value="upload">Upload Video</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Paper>

      {view === "table" && (
        <VideoTable videos={userVideos} loading={loading} />
      )}
      {view === "grid" && (
        <VideoGrid videos={userVideos} loading={loading} />
      )}
      {view === "upload" && (
        <FileUpload userId={id} onUploadSuccess={handleUploadSuccess} />
      )}

      

      {/* Dialogs */}
      <BlockUserDialog
        open={openDialog === "blockuser"}
        handleClose={handleCloseDialog}
        userId={id}
      />
      <FollowersDialog
        open={openDialog === "followers"}
        handleClose={handleCloseDialog}
        userId={id}
      />
      <FollowingDialog
        open={openDialog === "following"}
        handleClose={handleCloseDialog}
        userId={id}
      />
    </Grid>
  );
};

export default SingleUser;









