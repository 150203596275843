import React from "react";
import { Avatar, Chip, Box, Tooltip, IconButton } from "@mui/material";
import { FiEye, FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";

// Helper function to detect the device type from deviceId
const detectDeviceType = (deviceId) => {
  if (!deviceId) return 'Unknown';
  if (deviceId.includes(':')) {
    return 'iOS';
  } else if (deviceId.startsWith('APA91') || deviceId.startsWith('eUAC')) {
    return 'Android';
  }
  return 'Unknown';
};

export const usersColumns = [
  {
    id: 'sno',
    header: 'S.No.',
    size: 50,
    Cell: ({ row }) => row.index + 1, // Display the serial number
  },
  {
    accessorKey: 'profilePicture',
    header: 'Image',
    size: 100,
    Cell: ({ cell }) => (
      <Avatar src={cell.getValue()} sx={{ width: 30, height: 30 }} />
    ),
  },
  {
    accessorKey: 'userName',
    header: 'User  Name',
    size: 100,
  },
  {
    accessorKey: 'firstName', // If you want to merge first and last names
    header: 'Full Name',
    size: 100,
    Cell: ({ row }) => `${row.original.firstName || 'N/A'} ${row.original.lastName || ''}`.trim(), // Combine first and last name
  },
  {
    accessorKey: 'email',
    header: 'Email',
    size: 200,
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    size: 100,
    Cell: ({ cell }) => cell.getValue() || 'N/A',
  },
  {
    accessorKey: 'dob',
    header: 'Date of Birth',
    size: 150,
    Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString() || 'N/A',
  },
  {
    accessorKey: 'createdAt',
    header: 'Created At',
    size: 200,
    Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString() || 'N/A',
  },
  {
    accessorKey: 'countryAccess',
    header: 'Country Access',
    size: 100,
    Cell: ({ cell }) => cell.getValue() || 'N/A',
  },
  {
    accessorKey: 'appLanguage',
    header: 'App Language',
    size: 100,
    Cell: ({ cell }) => cell.getValue() || 'N/A',
  },
  {
    accessorKey: 'deviceType',
    header: 'Device',
    size: 100,
    Cell: ({ row }) => {
      const deviceId = row.original.deviceId;
      const deviceType = detectDeviceType(deviceId);
      return (
        <Chip
          label={deviceType}
          color={deviceType === 'Android' ? 'primary' : deviceType === 'iOS' ? 'secondary' : 'default'}
          size="small"
          variant="outlined"
        />
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 100,
    Cell: ({ cell }) => (
      <Chip
        label={cell.getValue()}
        color={cell.getValue() === 'active' ? 'success' : 'default'}
        size="small"
        variant="outlined"
      />
    ),
  },
  {
    accessorKey: "actions",
    header: "Actions",
    size: 100,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => (
      <Box sx={{ display: "flex", gap: 1 }}>
        {/* View Action */}
        <Tooltip title="View">
          <IconButton component={Link} to={`/users/${row.original.id}`}>
            <FiEye />
          </IconButton>
        </Tooltip>
        {/* <IconButton component={Link} to={`/customers/${row.original.id}`} color="primary">
          <FiEye />
        </IconButton> */}

        {/* Uncomment the following block to enable delete functionality */}
        {/* <Tooltip title="Delete">
          <IconButton
            color="error"
            onClick={() => handleDeleteRow(row.original)}
            disabled={loading} // Optionally disable if loading
          >
            <FiTrash />
          </IconButton>
        </Tooltip> */}
      </Box>
    ),
  },
];










