import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { CircularProgress, Box } from "@mui/material";

const Table = ({
  data,
  fields,
  currentPage,
  totalRecords,
  onPageChange,
  onPageSizeChange,
  pageSize,
  isLoading,
  globalFilter = "",
  onGlobalFilterChange,
  enableGlobalFilter = false,
  enableTopToolBar = true,
  enableBottomToolBar = true,
  enablePagination = true,
  enableRowSelection = false,
  enableColumnFilters = false,
  enableColumnDragging = false,
}) => {
  const columns = useMemo(() => fields, [fields]);

  return (
    <Box sx={{ position: 'relative' }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ isLoading }}
        getRowId={(row) => row.id}
        manualPagination
        manualGlobalFilter // Use manual global filter to handle server-side filtering
        enableColumnDragging={enableColumnDragging}
        enableColumnOrdering
        enableRowSelection={enableRowSelection}
        enableColumnFilters={enableColumnFilters}
        enablePagination={enablePagination}
        enableBottomToolbar={enableBottomToolBar}
        enableTopToolbar={enableTopToolBar}
        globalFilter={globalFilter} // Global filter state
        onGlobalFilterChange={onGlobalFilterChange} // Callback for when the search input changes
        enableGlobalFilter={enableGlobalFilter} // Enable global search bar inside the table
        rowCount={totalRecords}
        muiTablePaginationProps={{
          count: totalRecords,
          page: currentPage - 1,
          rowsPerPage: pageSize,
          onPageChange: (event, newPage) => onPageChange(newPage + 1),
          onRowsPerPageChange: (event) => onPageSizeChange(Number(event.target.value)),
        }}
        renderLoading={() => (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        )}
        muiTableBodyRowProps={{ hover: false }}
        muiTablePaperProps={{
          sx: {
            padding: "10px",
            borderRadius: "10px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
          },
        }}
        muiTableContainerProps={{
          sx: {
            borderRadius: "10px",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        }}
        muiTableHeadProps={{
          sx: {
            "& tr th": {
              padding: "8px 12 px",
              borderWidth: "1px",
              borderColor: "divider",
              borderStyle: "solid",
            },
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              padding: "8px 12px",
              borderWidth: "1px",
              borderColor: "divider",
              borderStyle: "solid",
            },
          },
        }}
      />
    </Box>
  );
};

export default Table;








// import React, { useMemo } from "react";
// import MaterialReactTable from "material-react-table";
// import {  Box, Skeleton } from "@mui/material";

// const Table = ({
//   data,
//   fields,
//   currentPage,
//   totalRecords,
//   onPageChange,
//   onPageSizeChange,
//   pageSize,
//   isLoading,
//   globalFilter = "",
//   onGlobalFilterChange,
//   enableGlobalFilter = false,
//   enableTopToolBar = true,
//   enableBottomToolBar = true,
//   enablePagination = true,
//   enableRowSelection = false,
//   enableColumnFilters = false,
//   enableColumnDragging = false,
// }) => {
//   // Memoize columns for performance
//   const columns = useMemo(() => fields, [fields]);

//   // Render a skeleton placeholder while loading
//   const renderSkeletonRows = () =>
//     Array.from({ length: pageSize }).map((_, index) => (
//       <tr key={index}>
//         {columns.map((col, colIndex) => (
//           <td key={colIndex}>
//             <Skeleton variant="text" width="80%" />
//           </td>
//         ))}
//       </tr>
//     ));

//   return (
//     <Box sx={{ position: "relative" }}>
//       <MaterialReactTable
//         columns={columns}
//         data={data}
//         state={{ isLoading }}
//         getRowId={(row) => row.id}
//         manualPagination
//         manualGlobalFilter
//         enableColumnDragging={enableColumnDragging}
//         enableColumnOrdering
//         enableRowSelection={enableRowSelection}
//         enableColumnFilters={enableColumnFilters}
//         enablePagination={enablePagination}
//         enableBottomToolbar={enableBottomToolBar}
//         enableTopToolbar={enableTopToolBar}
//         globalFilter={globalFilter}
//         onGlobalFilterChange={onGlobalFilterChange}
//         enableGlobalFilter={enableGlobalFilter}
//         rowCount={totalRecords}
//         muiTablePaginationProps={{
//         count: totalRecords,
//         page: currentPage - 1,
//         rowsPerPage: pageSize,
//         onPageChange: (event, newPage) => onPageChange(newPage + 1),
//         onRowsPerPageChange: (event) =>
//         onPageSizeChange(Number(event.target.value)),
//         }}
//         renderLoading={() => (
//           <Box sx={{ padding: "20px" }}>
//             <table style={{ width: "100%" }}>{renderSkeletonRows()}</table>
//           </Box>
//         )}
//         muiTableBodyRowProps={{ hover: false }}
//         muiTablePaperProps={{
//           sx: {
//             padding: "10px",
//             borderRadius: "10px",
//             borderStyle: "solid",
//             borderWidth: "1px",
//             borderColor: "divider",
//           },
//         }}
//         muiTableContainerProps={{
//           sx: {
//             borderRadius: "10px",
//           },
//         }}
//         muiTableHeadCellProps={{
//           sx: {
//             fontSize: "14px",
//             fontWeight: "bold",
//           },
//         }}
//         muiTableHeadProps={{
//           sx: {
//             "& tr th": {
//               padding: "8px 12px",
//               borderWidth: "1px",
//               borderColor: "divider",
//               borderStyle: "solid",
//             },
//           },
//         }}
//         muiTableBodyProps={{
//           sx: {
//             "& tr td": {
//               padding: "8px 12px",
//               borderWidth: "1px",
//               borderColor: "divider",
//               borderStyle: "solid",
//             },
//           },
//         }}
//       />
//     </Box>
//   );
// };

// export default Table;







// import React, { useMemo } from "react";
// import MaterialReactTable from "material-react-table";
// import { CircularProgress, Box } from "@mui/material";

// const Table = ({
//   data,
//   fields,
//   currentPage,
//   totalRecords,
//   onPageChange,
//   onPageSizeChange,
//   pageSize,
//   enableTopToolBar,
//   enableBottomToolBar,
//   enablePagination,
//   enableRowSelection,
//   enableColumnFilters,
//   enableColumnDragging,
//   enableGlobalFilter,
//   onGlobalFilterChange,
//   globalFilter,

//   loading,
// }) => {
//   const columns = useMemo(() => fields, [fields]);

//   return (
//     <Box sx={{ position: 'relative' }}>
//       <MaterialReactTable
//         columns={columns}
//         data={data}
//         getRowId={(row) => row.id}
//         manualPagination
//         rowCount={totalRecords}
//         // Enable features
//         enableColumnDragging={enableColumnDragging}
//         enableColumnOrdering
//         enableRowSelection={enableRowSelection}
//         enableColumnFilters={enableColumnFilters}
//         enablePagination={enablePagination}
//         enableBottomToolbar={enableBottomToolBar}
//         enableTopToolbar={enableTopToolBar}
//         manualGlobalFilter
//         enableGlobalFilter={enableGlobalFilter} // Display global search box
//         globalFilter={globalFilter} // Controlled global filter
//         onGlobalFilterChange={onGlobalFilterChange} // Callback for search
      

//         // Pagination props
//         muiTablePaginationProps={{
//           count: totalRecords,
//           page: currentPage - 1,
//           rowsPerPage: pageSize,
//           onPageChange: (event, newPage) => onPageChange(newPage + 1),
//           onRowsPerPageChange: (event) => onPageSizeChange(Number(event.target.value)),
//         }}

    
 

//         muiTableBodyRowProps={{ hover: false }}
//         muiTablePaperProps={{
//           sx: {
//             padding: "10px",
//             borderRadius: "10px",
//             borderStyle: "solid",
//             borderWidth: "1px",
//             borderColor: "divider",
//           },
//         }}
//         muiTableContainerProps={{
//           sx: {
//             borderRadius: "10px",
//             padding: "0",
//           },
//         }}
//         muiTableHeadProps={{
//           sx: {
//             "& tr th": {
//               padding: "8px 12px",
//               borderWidth: "1px",
//               borderColor: "divider",
//               borderStyle: "solid",
//             },
//           },
//         }}
//         muiTableBodyProps={{
//           sx: {
//             "& tr td": {
//               padding: "8px 12px",
//               borderWidth: "1px",
//               borderColor: "divider",
//               borderStyle: "solid",
//             },
//           },
//         }}
//       />

//       {loading && (
//         <Box
//           sx={{
//             position: 'absolute',
//             top: 0, left: 0,
//             width: '100%', height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.7)',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 1000,
//           }}
//         >
//           <CircularProgress />
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default Table;